import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import AppLayout from 'layouts/AppLayout';
import Wrapper from 'components/Wrapper';
import TopWrapper from 'components/TopWrapper';
import Container from 'components/Container';
import Heading from 'components/Heading';
import BaseTable from 'components/BaseTable';
import Badge from 'components/Badge';
import Button from 'components/Button';
import File from 'components/File';
import {
    STATEMENT_GET_CLAIMS_LIST,
    MMT_GET_LOSS_CLAIM_ONLINE_DOCTOR,
} from 'api';
import styles from './styles.module.scss';

class InsuranceClaimsPage extends React.Component {
    constructor(props) {
        super(props);

        this.store = props.store;

        this.state = {
            loading: false,
            title: 'Мои страховые случаи',
            claimList: [],
        };
    }

    async getClaimsList() {
        this.setState({
            ...this.state,
            loading: true,
        });

        await STATEMENT_GET_CLAIMS_LIST(false, true).then(async (data) => {
            const promises = [];

            let { result: { claims: claimList = [] } } = data;

            for (const index in claimList) {
                const element = claimList[index];

                switch (element.partner) {
                case 'onlinedoctor':
                    promises.push(MMT_GET_LOSS_CLAIM_ONLINE_DOCTOR(element.claimId, element.policy.policyId));
                    break;
                default:
                    break;
                }
            }

            await Promise.all(promises)
                .then((results = []) => {
                    results.forEach((result) => {
                        const { result: { lossClaimId, doctor, hasConclusion } } = result;

                        claimList = claimList.map((item) => {
                            if (item.claimId === lossClaimId) {
                                return {
                                    ...item,
                                    doctor,
                                    hasConclusion,
                                };
                            }

                            return item;
                        });
                    });
                });

            this.setState({
                ...this.state,
                claimList,
                loading: false,
            }, () => {
                setTimeout(() => {
                    this.getClaimsList();
                }, 60000);
            });
        });
    }

    componentDidMount() {
        document.title = this.state.title;
        this.getClaimsList();
    }

    render() {
        const getItemRedirectPath = (id, canEdit = false) => {
            if (canEdit) {
                return `/claim?claimId=${id}`;
            }

            return `/claims/${id}`;
        };

        const RedirectHandler = ({ item }) => {
            if (item.canEdit) {
                return (<Button
                    to={getItemRedirectPath(item.claimId, item.canEdit)}
                    variant="primaryOutline"
                    size="sm"
                >
                    Подписать
                </Button>);
            }

            return (<Link to={getItemRedirectPath(item.claimId, item.canEdit)} data-rel="arrow" />);
        };

        return (
            <AppLayout
                hasBackground={false}
            >
                <div className={styles.InsuranceClaimsPageWrapper}>
                    <Wrapper>
                        <TopWrapper>
                            <Container fluid>
                                <Heading>{ this.state.title }</Heading>

                                {this.state.claimList.length !== 0
                                    ? (
                                        <BaseTable className={styles.InsuranceClaimsPageTable}>
                                            <thead>
                                                <tr>
                                                    <th data-col="number">№ Страхового возмещения</th>
                                                    <th data-col="date">Дата</th>
                                                    <th data-col="risk">Риск</th>
                                                    <th data-col="status">Статус</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {this.state.claimList.map((item, itemIndex) => (
                                                    <tr
                                                        key={itemIndex}
                                                        data-pending={!(item.number)}
                                                        data-notif={item.unreadMessagesByClientCount}
                                                        onClick={() => {
                                                            if (item.partner === 'uralsibins') {
                                                                this.props.history.push(getItemRedirectPath(item.claimId, item.canEdit));
                                                            }
                                                        }}
                                                    >
                                                        <td data-col="number">
                                                            {item.partner === 'uralsibins' ? (
                                                                <Link to={getItemRedirectPath(item.claimId, item.canEdit)}>{ item.number }</Link>
                                                            ) : (
                                                                <span>{ item.number }</span>
                                                            )}
                                                        </td>

                                                        <td data-col="date">{ moment(item.dateAndTime).format('DD.MM.YYYY') }</td>

                                                        <td data-col="risk">
                                                            <span data-rel="title">
                                                                { item.previewText }

                                                                {item.hasConclusion && (
                                                                    <Badge style={{ backgroundColor: '#95c11f', margin: '0 0 5px 8px' }}>Доступно заключение врача</Badge>
                                                                )}
                                                            </span>

                                                            <span data-rel="subTitle">{ `${item.policy.insuranceProgram} — ${item.policy.contractNumber}` }</span>

                                                            {item.doctor && (
                                                                <span data-rel="subTitle">{ `${item.doctor.professions.join(', ')} — ${[item.doctor.surname, item.doctor.patronymic, item.doctor.name].join(' ')}` }</span>
                                                            )}

                                                            {item.hasConclusion && (
                                                                <div style={{ marginTop: '10px' }}>
                                                                    <File
                                                                        name="Заключение"
                                                                        href={`/api?method=mmt&action=getConclusionFileOnlineDoctor&lossClaimId=${item.claimId}&policyId=${item.policy.policyId}`}
                                                                    />
                                                                </div>
                                                            )}
                                                        </td>

                                                        <td data-col="status">{ typeof item.status === 'object' ? item.status.name : item.status }</td>

                                                        {(item.partner === 'uralsibins') && (
                                                            <td data-col="arrow">
                                                                <RedirectHandler item={item} />
                                                            </td>
                                                        )}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </BaseTable>
                                    )
                                    : (
                                        <div style={{
                                            marginTop: 60,
                                            textAlign: 'center',
                                        }}>
                                            <Heading
                                                level="3"
                                                loading={this.state.loading}
                                            >{ !this.state.loading
                                                    ? 'У Вас нет отправленных заявлений на урегулирование страхового случая'
                                                    : '' }</Heading>
                                        </div>
                                    )
                                }
                            </Container>
                        </TopWrapper>
                    </Wrapper>
                </div>
            </AppLayout>

        );
    }
}

export default inject('store')(observer(withRouter(InsuranceClaimsPage)));
