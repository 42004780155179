/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import cn from 'classnames';
import { inject, observer } from 'mobx-react';

import AppLayout from 'layouts/AppLayout';

import Wrapper from 'components/Wrapper';
import Heading from 'components/Heading';
import Container from 'components/Container';
import TopWrapper from 'components/TopWrapper';
import InputField from 'components/InputField';
import SelectField from 'components/SelectField';
import DateInputField from 'components/DateInputField';
import MaskedInputField from 'components/MaskedInputField';
import Button from 'components/Button';
import File from 'components/File';
import FileLoader from 'components/FileLoader';
import ToggleTabs, { ToggleTabsItem } from 'components/ToggleTabs';
import Modal from 'components/Modal';
import ModalOverlay from 'components/ModalOverlay';
import CheckSmsCodeCard from 'components/CheckSmsCodeCard';
import AutocompleteInputField from 'components/AutocompleteInputField';

import {
    STATEMENT_SEND_SMS,
    DATA_CHANGE_GET_ITEM,
    DATA_CHANGE_CREATE_POLICY,
    DATA_CHANGE_RISK_SELECTOR_LIST,
    DATA_CHANGE_CHANGE_REQUEST,
    DATA_CHANGE_SEND_CHANGE_REQUEST,
    DATA_CHANGE_ADD_REQUEST_FILE,
    DATA_CHANGE_DELETE_REQUEST_FILE,
    SUGGESTIONS_ADDRESS,
} from 'api';

import styles from './styles.module.scss';
import {sanitizeText} from "../../helpers/helpers";

const makeid = (length) => {
    let result = '';

    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i += 1) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
};

class ProfileUpdateRequest extends React.Component {
    constructor(props) {
        super(props);

        this.store = props.store;

        this.state = {
            title: 'Подать заявление по договору страхования',
            riskSelectionsList: null,

            activeTab: 0,
            requestId: null,
            requestLastUpdatedAt: null,

            selections: [],
            riskSelectionsSelects: [],
            form: null,
            fileTypes: [],
            files: [],

            checkout: {
                isShowForm: false,
                isShowSuccessModal: false,
                code: '',
                codeError: null,
                resendLoading: false,
                submitLoading: false,
            },

            applicantError: {},
            insuredError: {},
            beneficiaryError: {},

            beneficiariesRefs: {},

            suggestionsAddressResult: {},

            loading: false,
        };

        this.onChange = this.onChange.bind(this);
        this.debounceEditRequest = _.debounce(this.editRequest, 1500);
        this.debounceFindSuggestionsAddress = _.debounce(this.findSuggestionsAddress, 50);
    }

    activeSelection() {
        return this.state.riskSelectionsList.selections[this.state.activeTab] || {};
    }

    prepareSelects(level) {
        const selectedCurrentLevel = this.state.selections.find((selectionsItem) => selectionsItem.selectionLevelCode === level.code);

        if (selectedCurrentLevel) {
            const selectedCurrentLevelSelections = level.selections.find((selectionsItem) => selectionsItem.code === selectedCurrentLevel.code);

            if (selectedCurrentLevelSelections && selectedCurrentLevelSelections.nextLevel) {
                const findSelect = this.state.riskSelectionsSelects.find((riskSelectionsSelectsItem) => riskSelectionsSelectsItem.code === selectedCurrentLevelSelections.nextLevel.code);

                if (!findSelect) {
                    this.setState({
                        ...this.state,
                        riskSelectionsSelects: [
                            ...this.state.riskSelectionsSelects,
                            selectedCurrentLevelSelections.nextLevel,
                        ],
                    }, () => {
                        this.prepareSelects(selectedCurrentLevelSelections.nextLevel);
                    });
                } else if (JSON.stringify(findSelect.selections) !== JSON.stringify(selectedCurrentLevelSelections.nextLevel.selections)) {
                    const riskSelectionsSelectsCopy = [...this.state.riskSelectionsSelects];
                    const findSelectIndex = riskSelectionsSelectsCopy.indexOf(findSelect);
                    riskSelectionsSelectsCopy.splice(findSelectIndex, 1, selectedCurrentLevelSelections.nextLevel);

                    this.setState({
                        ...this.state,
                        riskSelectionsSelects: riskSelectionsSelectsCopy,
                    });
                } else {
                    this.prepareSelects(selectedCurrentLevelSelections.nextLevel);
                }
            }
        } else if (this.state.riskSelectionsSelects.length === 0) {
            this.setState({
                ...this.state,
                riskSelectionsSelects: [level],
            });
        }
    }

    async fillRisks() {
        const { policyId } = this.props.match.params;

        this.store.loading.start();

        return DATA_CHANGE_RISK_SELECTOR_LIST(policyId)
            .then((data) => {
                const { result: { risks } } = data;

                this.setState({
                    ...this.state,
                    riskSelectionsList: risks[0],
                }, () => {
                    this.store.loading.complete();
                });
            });
    }

    async createRequest() {
        const { policyId } = this.props.match.params;

        const activeSelection = this.activeSelection();

        const selections = [
            {
                code: activeSelection.code,
                selectionLevelCode: 'ChangeType',
            },
            ...this.state.selections,
        ];

        this.setState({
            ...this.state,
            loading: true,
        }, () => {
            this.store.loading.start();
        });

        return DATA_CHANGE_CREATE_POLICY(policyId, selections)
            .then(async (data) => {
                const { result: { id } } = data;

                await DATA_CHANGE_GET_ITEM(id)
                    .then((itemData) => {
                        const { result: { form = {}, files = [], fileTypes = [], lastUpdatedAt } } = itemData;

                        Object.keys(form).forEach((key) => {
                            if (!form[key]) form[key] = '';
                        });

                        if ('insureds' in form) {
                            form.insureds = form.insureds.map((item) => {
                                const localItem = { ...item };

                                localItem.tempId = makeid(16);

                                return localItem;
                            });
                        }

                        if ('beneficiaries' in form) {
                            form.beneficiaries = form.beneficiaries.map((item) => {
                                const localItem = { ...item };

                                localItem.tempId = makeid(16);

                                return localItem;
                            });
                        }

                        this.setState({
                            ...this.state,
                            requestId: id,
                            requestLastUpdatedAt: lastUpdatedAt,
                            form,
                            files,
                            fileTypes,
                            loading: false,
                        }, () => {
                            this.store.loading.complete();
                        });
                    });
            });
    }

    editRequest() {
        return DATA_CHANGE_CHANGE_REQUEST(this.state.requestId, this.state.form);
    }

    getSelectionValue(item) {
        const findSelection = this.state.selections.find((selection) => selection.selectionLevelCode === item.code) || {};

        return findSelection.code || -1;
    }

    onChangeSelections(e, item) {
        let selections = [...this.state.selections];
        const { value: code } = e.target;

        const findItem = selections.find((elem) => elem.selectionLevelCode === item.code);
        const findIndex = selections.indexOf(findItem);

        if (findIndex !== -1) {
            selections.splice(findIndex, selections.length);
        }

        selections = [
            ...selections,
            {
                code,
                selectionLevelCode: item.code,
            },
        ];

        this.setState({
            ...this.state,
            selections,
        }, () => {
            this.state.riskSelectionsSelects.forEach((riskSelectionsSelectsItem) => {
                if (riskSelectionsSelectsItem.code === item.code) {
                    item.selections.forEach((selectionsItem) => {
                        if (selectionsItem.code === code && !selectionsItem.nextLevel) {
                            this.createRequest();
                        }
                    });
                }
            });

            this.prepareSelects(item);
        });
    }

    deleteFile(id) {
        const files = [...this.state.files];
        const index = files.findIndex((item) => item.fileId === id);

        files.splice(index, 1);

        this.setState({
            ...this.state,
            files,
        }, () => {
            DATA_CHANGE_DELETE_REQUEST_FILE(this.state.requestId, id);
        });
    }

    async onChangeFiles(e, type, tempId) {
        const uploadedFiles = [...e.target.files];
        const files = [...this.state.files];
        const filesError = [];

        e.target.value = null;

        for (let i = 0; i < uploadedFiles.length; i += 1) {
            const maxFileLength = 9;
            const maxFileSize = 10;
            const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;
            const file = uploadedFiles[i];
            const { size: fileSize, name: fileName } = file;
            const fileSizeMB = (fileSize / (1024 * 1024)).toFixed(2);

            const checkFileTypeLength = Boolean(files.filter((item) => item.fileType === type).length >= maxFileLength);
            const checkAllowedExtensions = allowedExtensions.exec(fileName);

            if (checkFileTypeLength) {
                filesError.push(`К загрузке допускается всего ${maxFileLength} файлов.`);

                break;
            } else if (!checkAllowedExtensions) {
                filesError.push(`Недопустимое расширение у файла ${fileName}`);
            } else if (fileSizeMB > maxFileSize) {
                filesError.push(`Размер файла ${fileName} превышает допустимые ${maxFileSize}мб.`);
            } else {
                files.push({
                    name: fileName,
                    fileType: type,
                    tempIndex: i,
                    tempId,
                });

                this.setState({
                    ...this.state,
                    files,
                });

                await DATA_CHANGE_ADD_REQUEST_FILE(type, this.state.requestId, file)
                    .then((data) => {
                        const { result } = data;

                        const index = files.findIndex((item) => item.tempIndex === i);

                        files[index].fileId = result;
                        delete files[index].tempIndex;

                        this.setState({
                            ...this.state,
                            files,
                        });
                    }).catch((e) => {
                    filesError.push(e.data.message + " " + fileName);
                    files.pop();

                    this.setState({
                        ...this.state,
                        files,
                    })
                });
            }
        }

        if (filesError.length !== 0) {
            this.store.app.setModalData({
                title: 'Ошибка',
                body: filesError.join('\n'),
            });
        }
    }

    findSuggestionsAddress(name, value, index) {
        SUGGESTIONS_ADDRESS(value).then((data) => {
            let { suggestions = [] } = data;

            suggestions = suggestions.map((item) => item.unrestricted_value);

            this.setState({
                ...this.state,
                suggestionsAddressResult: {
                    [`${name}-${index}`]: suggestions,
                },
            });
        });
    }

    onChange(e) {
        const { value, name } = e.target;

        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                [name]: sanitizeText(value),
            },
        }, () => {
            this.debounceEditRequest();
        });
    }

    onChangeInsured(e, index) {
        const insureds = [...this.state.form.insureds];
        const { value, name } = e.target;

        switch (name) {
        case 'insuredRegistrationAddress':
            this.debounceFindSuggestionsAddress(name, value, index);

            insureds[index][name] = sanitizeText(value);
            break;
        default:
            insureds[index][name] = sanitizeText(value);
            break;
        }

        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                insureds,
            },
        }, () => {
            this.debounceEditRequest();
        });
    }

    onChangeBeneficiary(e, index) {
        const beneficiaries = [...this.state.form.beneficiaries];
        const { value, name } = e.target;

        switch (name) {
        case 'beneficiaryBirthplace':
            this.debounceFindSuggestionsAddress(name, value, index);
            break;
        default:
            break;
        }

        switch (name) {
        case 'beneficiaryPayoutPercentage':
            if (value > 100) {
                beneficiaries[index][name] = 100;
            } else {
                beneficiaries[index][name] = sanitizeText(value);
            }
            break;
        default:
            beneficiaries[index][name] = sanitizeText(value);
            break;
        }

        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                beneficiaries,
            },
        }, () => {
            this.debounceEditRequest();
        });
    }

    addBeneficiary() {
        if ((this.state.form.beneficiaries || []).length < 4) {
            const base = {
                beneficiaryBirthday: '',
                beneficiaryBirthplace: '',
                beneficiaryName: '',
                beneficiaryPassportSeries: '',
                beneficiaryPassportNumber: '',
                beneficiaryPassportIssueDate: '',
                beneficiaryPassportIssuer: '',
                beneficiaryPassportIssuerCode: '',
                beneficiaryPayoutPercentage: 0,
            };

            const beneficiaries = [
                ...this.state.form.beneficiaries,
                base,
            ];

            this.setState({
                ...this.state,
                form: {
                    ...this.state.form,
                    beneficiaries,
                },
            }, () => {
                const beneficiariesLength = this.state.form.beneficiaries.length;

                if (beneficiariesLength) {
                    const elem = document.getElementById(`beneficiaries-${beneficiariesLength - 1}`);

                    elem.scrollIntoView();
                }

                this.debounceEditRequest();
            });
        }
    }

    removeBeneficiary(index) {
        const beneficiaries = [...this.state.form.beneficiaries];

        beneficiaries.splice(index, 1);

        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                beneficiaries,
            },
        }, () => {
            this.debounceEditRequest();
        });
    }

    async setActiveTab(index) {
        if (this.state.loading) return false;

        return this.setState({
            ...this.state,
            activeTab: index,
            requestId: null,
            selections: [],
            riskSelectionsSelects: [],
            form: null,
            fileTypes: [],
            files: [],
        }, async () => {
            const activeSelection = this.activeSelection();

            if (!activeSelection.nextLevel) {
                await this.createRequest();
            } else {
                this.prepareSelects(activeSelection.nextLevel);
            }
        });
    }

    openConfirmationForm(e) {
        e.preventDefault();

        this.setState({
            ...this.state,
            checkout: {
                ...this.state.checkout,
                submitLoading: true,
            },
        });

        STATEMENT_SEND_SMS(this.store.profile.user.phone)
            .then(() => {
                this.setState({
                    ...this.state,
                    checkout: {
                        ...this.state.checkout,
                        isShowForm: true,
                        codeError: null,
                        submitLoading: false,
                    },
                });
            }).catch((error) => {
                const { message } = error.data;

                this.setState({
                    ...this.state,
                    checkout: {
                        ...this.state.checkout,
                        codeError: message,
                        submitLoading: false,
                    },
                });
            });
    }

    closeConfirmationForm() {
        this.setState({
            ...this.state,
            checkout: {
                isShowForm: false,
                isShowSuccessModal: false,
                code: '',
                codeError: null,
                resendLoading: false,
                submitLoading: false,
            },
        });
    }

    sendRequest() {
        DATA_CHANGE_SEND_CHANGE_REQUEST(this.state.requestId, this.state.checkout.code)
            .then(() => {
                this.setState({
                    ...this.state,
                    requestId: null,
                    checkout: {
                        ...this.state.checkout,
                        isShowForm: false,
                        isShowSuccessModal: true,
                        codeError: null,
                        submitLoading: false,
                    },
                });
            }).catch((error) => {
                const { message } = error.data;

                this.setState({
                    ...this.state,
                    checkout: {
                        ...this.state.checkout,
                        codeError: message,
                        submitLoading: false,
                    },
                });
            });
    }

    async onSubmit(e) {
        e.preventDefault();

        this.setState({
            ...this.state,
            checkout: {
                ...this.state.checkout,
                submitLoading: true,
            },
        });

        await this.editRequest();

        this.sendRequest();
    }

    submitDisabled() {
        const applicantError = {};
        const insuredError = {};
        const beneficiaryError = {};

        let isDisabled = this.state.checkout.submitLoading || this.state.checkout.submitDisable;

        if (!this.state.files.length) isDisabled = true;

        if (!this.state.form.applicantEmail) {
            isDisabled = true;
        } else if (this.state.form.applicantEmail && !new RegExp('^([a-zA-Z0-9_-]+\\.)*[a-zA-Z0-9_-]+@[a-z0-9_-]+(\\.[a-z0-9_-]+)*\\.[a-z]{2,6}$').test(this.state.form.applicantEmail)) {
            isDisabled = true;

            applicantError.applicantEmail = true;
        }

        if (this.activeSelection().code === 'Insured') {
            (this.state.form.insureds || []).forEach((item) => {
                if (item.insuredInn && item.insuredInn.length < 12) {
                    isDisabled = true;

                    insuredError.insuredInn = true;
                }
            });
        }

        if (this.activeSelection().code === 'Beneficiary') {
            let percentage = 0;

            (this.state.form.beneficiaries || []).forEach((item) => {
                percentage += parseInt(item.beneficiaryPayoutPercentage, 10);
            });

            if (percentage > 100 || percentage < 100) {
                isDisabled = true;

                beneficiaryError.beneficiaryPayoutPercentage = 'Сумма долей всех выгодоприобретателей должна быть 100%';
            }
        }

        if (JSON.stringify(this.state.applicantError) !== JSON.stringify(applicantError)) {
            this.setState({
                ...this.state,
                applicantError,
            });
        }

        if (JSON.stringify(this.state.insuredError) !== JSON.stringify(insuredError)) {
            this.setState({
                ...this.state,
                insuredError,
            });
        }

        if (JSON.stringify(this.state.beneficiaryError) !== JSON.stringify(beneficiaryError)) {
            this.setState({
                ...this.state,
                beneficiaryError,
            });
        }

        return isDisabled;
    }

    async componentDidMount() {
        document.title = this.state.title;

        await this.fillRisks();

        await this.setActiveTab(1);
    }

    render() {
        return (
            <AppLayout
                hasBackground={false}
            >
                {this.state.checkout.isShowForm && (
                    <ModalOverlay handleClose={() => this.closeConfirmationForm()}>
                        <CheckSmsCodeCard
                            code={this.state.checkout.code}
                            codeError={this.state.checkout.codeError}
                            resendLoading={this.state.checkout.resendLoading}
                            submitLoading={this.state.checkout.submitLoading}
                            resendTime={60}
                            onCodeChange={(e) => this.setState({
                                ...this.state,
                                checkout: {
                                    ...this.state.checkout,
                                    code: e.target.value,
                                },
                            })}
                            onClose={() => this.closeConfirmationForm()}
                            onResend={() => this.sendConfirmationCode()}
                            onSubmit={(e) => this.onSubmit(e)}
                        />
                    </ModalOverlay>
                )}

                {this.state.checkout.isShowSuccessModal && (
                    <Modal
                        title={ 'Спасибо!' }
                        body={
                            <p>Заявление успешно отправлено</p>
                        }
                        footer={
                            <Button
                                style={{ width: '100%' }}
                                size="lg"
                                variant="primary"
                                onClick={() => this.closeConfirmationForm()}>{ 'Продолжить' }</Button>
                        }
                        handleClose={() => this.closeConfirmationForm()} />
                )}

                <div className={styles.Wrapper}>
                    <Wrapper>
                        <TopWrapper>
                            <Container fluid>
                                <Heading>{ this.state.title }</Heading>

                                {this.state.riskSelectionsList && (
                                    <ToggleTabs>
                                        {this.state.riskSelectionsList.selections.map((item, index) => (
                                            (item.name !== "Изменение застрахованных") && <ToggleTabsItem
                                                key={index}
                                                active={this.state.activeTab === index}
                                                onClick={() => this.setActiveTab(index)}
                                            >{ item.name }</ToggleTabsItem>
                                        ))}
                                        <ToggleTabsItem
                                            key='link'
                                            active=''
                                            onClick= {()=>window.open('https://pyrus.sovcombank.ru/form/416066', "_blank")}
                                        >{ 'Подать заявление по договору страхования' }</ToggleTabsItem>
                                    </ToggleTabs>
                                )}

                                <div className={styles.Side}>
                                    <div className={styles.LeftSide}>
                                        {this.state.riskSelectionsSelects.map((select, selectIndex) => (
                                            <div
                                                key={selectIndex}
                                                className={styles.CardRow}
                                            >
                                                <SelectField
                                                    label={select.name}
                                                    size="lg"
                                                    name={select.code}
                                                    placeholder={select.name}
                                                    value={this.getSelectionValue(select)}
                                                    options={select.selections.map((selection) => ({
                                                        id: selection.code,
                                                        name: selection.name,
                                                    }))}
                                                    onChange={(e) => this.onChangeSelections(e, select)}
                                                />
                                            </div>
                                        ))}

                                        { false && this.state.form && this.state.form.insureds && this.state.riskSelectionsList && this.activeSelection().code === 'Insured' &&  (
                                            this.state.form.insureds.map((item, itemIndex) => (
                                                <div
                                                    key={itemIndex}
                                                    className={cn([styles.Card, styles.CardInsured])}
                                                >
                                                    <Heading level={4}>{`Сведения о застрахованном лице ${itemIndex + 1}`}</Heading>

                                                    <div
                                                        className={styles.CardWrapper}
                                                        style={{ marginTop: 55 }}
                                                    >
                                                        <div className={styles.CardRow}>
                                                            <div data-name="fullname">
                                                                <InputField
                                                                    label="ФИО"
                                                                    size="lg"
                                                                    type="text"
                                                                    placeholder="ФИО"
                                                                    name="insuredName"
                                                                    value={item.insuredName || ''}
                                                                    onChange={(e) => this.onChangeInsured(e, itemIndex)}
                                                                />
                                                            </div>

                                                            <div data-name="birthDate">
                                                                <DateInputField
                                                                    mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                                                                    label="Дата рождения"
                                                                    size="lg"
                                                                    icon="calendar"
                                                                    format="dd.MM.yyyy"
                                                                    placeholder="дд.мм.гггг"
                                                                    name="insuredBirthday"
                                                                    value={item.insuredBirthday && moment(item.insuredBirthday).toDate()}
                                                                    onChange={(value) => this.onChangeInsured({ target: { value: moment(value).format('YYYY-MM-DDTHH:mm:ss'), name: 'insuredBirthday' } }, itemIndex)}
                                                                    disabled
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className={styles.CardRow}>
                                                            <div data-name="passportSeries">
                                                                <MaskedInputField
                                                                    mask={[/[0-9]/, /\d/, /\d/, /\d/]}
                                                                    label={'Паспорт'}
                                                                    size="lg"
                                                                    type="text"
                                                                    placeholder="Серия"
                                                                    name="insuredPassportSeries"
                                                                    value={item.insuredPassportSeries || ''}
                                                                    onChange={(e) => this.onChangeInsured(e, itemIndex)}
                                                                />
                                                            </div>

                                                            <div data-name="passportNumber">
                                                                <MaskedInputField
                                                                    mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                                                    size="lg"
                                                                    type="text"
                                                                    placeholder="Номер"
                                                                    name="insuredPassportNumber"
                                                                    value={item.insuredPassportNumber || ''}
                                                                    onChange={(e) => this.onChangeInsured(e, itemIndex)}
                                                                />
                                                            </div>

                                                            <div data-name="passportIssueDate">
                                                                <DateInputField
                                                                    mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                                                                    maxDate={moment().toDate()}
                                                                    label="Дата выдачи"
                                                                    size="lg"
                                                                    icon="calendar"
                                                                    format="dd.MM.yyyy"
                                                                    placeholder="дд.мм.гггг"
                                                                    name="insuredPassportIssueDate"
                                                                    value={item.insuredPassportIssueDate && moment(item.insuredPassportIssueDate).toDate()}
                                                                    onChange={(value) => this.onChangeInsured({ target: { value: moment(value).format('YYYY-MM-DDTHH:mm:ss'), name: 'insuredPassportIssueDate' } }, itemIndex)}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className={styles.CardRow}>
                                                            <div data-name="passportIssuer">
                                                                <InputField
                                                                    label="Кем выдан"
                                                                    size="lg"
                                                                    type="text"
                                                                    placeholder="Кем выдан"
                                                                    name="insuredPassportIssuer"
                                                                    value={item.insuredPassportIssuer || ''}
                                                                    onChange={(e) => this.onChangeInsured(e, itemIndex)}
                                                                />
                                                            </div>

                                                            <div data-name="passportIssuerCode">
                                                                <MaskedInputField
                                                                    mask={[/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                                                                    label="Код подразделения"
                                                                    size="lg"
                                                                    type="text"
                                                                    placeholder="Код подразделения"
                                                                    name="insuredPassportIssuerCode"
                                                                    value={this.state.form.insuredPassportIssuerCode}
                                                                    onChange={this.onChange}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className={styles.CardRow}>
                                                            <MaskedInputField
                                                                mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                                                label="ИНН"
                                                                size="lg"
                                                                type="text"
                                                                placeholder="ИНН"
                                                                name="insuredInn"
                                                                value={item.insuredInn || ''}
                                                                error={this.state.insuredError.insuredInn}
                                                                onChange={(e) => this.onChangeInsured(e, itemIndex)}
                                                            />
                                                        </div>

                                                        <div className={styles.CardRow}>
                                                            <AutocompleteInputField
                                                                label="Адрес регистрации"
                                                                size="lg"
                                                                type="text"
                                                                placeholder="Адрес регистрации"
                                                                name="insuredRegistrationAddress"
                                                                value={item.insuredRegistrationAddress || ''}
                                                                items={this.state.suggestionsAddressResult[`insuredRegistrationAddress-${itemIndex}`] || []}
                                                                includeSearch={false}
                                                                setValue={(value) => this.onChangeInsured({ target: { value, name: 'insuredRegistrationAddress' } }, itemIndex) }
                                                                onChange={(e) => this.onChangeInsured(e, itemIndex)}
                                                            />
                                                        </div>

                                                        <div className={styles.CardRow}>
                                                            {this.state.fileTypes.map((fileType, fileTypeIndex) => (
                                                                <div
                                                                    key={fileTypeIndex}
                                                                    style={{ width: '100%', marginTop: fileTypeIndex !== 0 ? 30 : 0 }}
                                                                >
                                                                    <FileLoader
                                                                        title={'Добавить'}
                                                                        label={fileType.name}
                                                                        required={true}
                                                                        accept={".jpg,.jpeg,.png,.pdf"}
                                                                        onChange={(e) => this.onChangeFiles(e, fileType.code, item.tempId)}
                                                                        files={(
                                                                            this.state.files.filter((file) => file.fileType === fileType.code && file.tempId === item.tempId).map((file, fileIndex) => (
                                                                                <File
                                                                                    key={fileIndex}
                                                                                    name={file.name}
                                                                                    href={`/api?method=Insurance&action=getFile&FileId=${file.fileId}&FileName=${file.name}`}
                                                                                    loading={file.tempIndex !== undefined}
                                                                                    onRemove={file.fileId ? () => this.deleteFile(file.fileId) : undefined }
                                                                                />
                                                                            )).reverse()
                                                                        )}
                                                                    />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        )}

                                        {this.state.form && this.state.form.beneficiaries && this.state.riskSelectionsList && this.activeSelection().code === 'Beneficiary' && (
                                            <React.Fragment>
                                                {this.state.form.beneficiaries.map((item, itemIndex) => (
                                                    <div
                                                        id={`beneficiaries-${itemIndex}`}
                                                        key={itemIndex}
                                                        className={cn([styles.Card, styles.CardBeneficiary])}
                                                    >
                                                        <Heading level={4}>{`Сведения о выгодоприобретателе ${itemIndex + 1}`}</Heading>

                                                        <div
                                                            className={styles.CardWrapper}
                                                            style={{ marginTop: 55 }}
                                                        >
                                                            <div className={styles.CardRow}>
                                                                <div data-name="fullname">
                                                                    <InputField
                                                                        label="ФИО"
                                                                        size="lg"
                                                                        type="text"
                                                                        placeholder="ФИО"
                                                                        name="beneficiaryName"
                                                                        value={item.beneficiaryName}
                                                                        onChange={(e) => this.onChangeBeneficiary(e, itemIndex)}
                                                                    />
                                                                </div>

                                                                <div data-name="birthDate">
                                                                    <DateInputField
                                                                        mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                                                                        label="Дата рождения"
                                                                        size="lg"
                                                                        icon="calendar"
                                                                        format="dd.MM.yyyy"
                                                                        placeholder="дд.мм.гггг"
                                                                        name="beneficiaryBirthday"
                                                                        value={item.beneficiaryBirthday && moment(item.beneficiaryBirthday).toDate()}
                                                                        onChange={(value) => this.onChangeBeneficiary({ target: { value: moment(value).format('YYYY-MM-DDTHH:mm:ss'), name: 'beneficiaryBirthday' } }, itemIndex)}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className={styles.CardRow}>
                                                                <AutocompleteInputField
                                                                    label="Место рождения"
                                                                    size="lg"
                                                                    type="text"
                                                                    placeholder="Место рождения"
                                                                    name="beneficiaryBirthplace"
                                                                    value={item.beneficiaryBirthplace || ''}
                                                                    items={this.state.suggestionsAddressResult[`beneficiaryBirthplace-${itemIndex}`] || []}
                                                                    includeSearch={false}
                                                                    setValue={(value) => this.onChangeBeneficiary({ target: { value, name: 'beneficiaryBirthplace' } }, itemIndex) }
                                                                    onChange={(e) => this.onChangeBeneficiary(e, itemIndex)}
                                                                />
                                                            </div>

                                                            <div className={styles.CardRow}>
                                                                <div data-name="passportSeries">
                                                                    <MaskedInputField
                                                                        mask={[/[0-9]/, /\d/, /\d/, /\d/]}
                                                                        label={'Паспорт'}
                                                                        size="lg"
                                                                        type="text"
                                                                        placeholder="Серия"
                                                                        name="beneficiaryPassportSeries"
                                                                        value={item.beneficiaryPassportSeries}
                                                                        onChange={(e) => this.onChangeBeneficiary(e, itemIndex)}
                                                                    />
                                                                </div>

                                                                <div data-name="passportNumber">
                                                                    <MaskedInputField
                                                                        mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                                                        size="lg"
                                                                        type="text"
                                                                        placeholder="Номер"
                                                                        name="beneficiaryPassportNumber"
                                                                        value={item.beneficiaryPassportNumber}
                                                                        onChange={(e) => this.onChangeBeneficiary(e, itemIndex)}
                                                                    />
                                                                </div>

                                                                <div data-name="passportIssueDate">
                                                                    <DateInputField
                                                                        mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                                                                        maxDate={moment().toDate()}
                                                                        label="Дата выдачи"
                                                                        size="lg"
                                                                        icon="calendar"
                                                                        format="dd.MM.yyyy"
                                                                        placeholder="дд.мм.гггг"
                                                                        name="beneficiaryPassportIssueDate"
                                                                        value={item.beneficiaryPassportIssueDate && moment(item.beneficiaryPassportIssueDate).toDate()}
                                                                        onChange={(value) => this.onChangeBeneficiary({ target: { value: moment(value).format('YYYY-MM-DDTHH:mm:ss'), name: 'beneficiaryPassportIssueDate' } }, itemIndex)}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className={styles.CardRow}>
                                                                <div data-name="passportIssuer">
                                                                    <InputField
                                                                        label="Кем выдан"
                                                                        size="lg"
                                                                        type="text"
                                                                        placeholder="Кем выдан"
                                                                        name="beneficiaryPassportIssuer"
                                                                        value={item.beneficiaryPassportIssuer}
                                                                        onChange={(e) => this.onChangeBeneficiary(e, itemIndex)}
                                                                    />
                                                                </div>

                                                                <div data-name="passportIssuerCode">
                                                                    <MaskedInputField
                                                                        mask={[/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                                                                        label="Код подразделения"
                                                                        size="lg"
                                                                        type="text"
                                                                        placeholder="Код"
                                                                        name="beneficiaryPassportIssuerCode"
                                                                        value={item.beneficiaryPassportIssuerCode}
                                                                        onChange={(e) => this.onChangeBeneficiary(e, itemIndex)}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className={styles.CardRow}>
                                                                <div data-name="percentage">
                                                                    <MaskedInputField
                                                                        mask={(val) => {
                                                                            const mask = [];

                                                                            for (let i = 0; i < val.length; i += 1) {
                                                                                mask.push(/[0-9]/);
                                                                            }

                                                                            return mask;
                                                                        }}
                                                                        label="Доля %"
                                                                        size="lg"
                                                                        type="text"
                                                                        placeholder="Доля %"
                                                                        name="beneficiaryPayoutPercentage"
                                                                        value={item.beneficiaryPayoutPercentage}
                                                                        error={this.state.beneficiaryError.beneficiaryPayoutPercentage}
                                                                        onChange={(e) => this.onChangeBeneficiary(e, itemIndex)}
                                                                    />
                                                                </div>
                                                            </div>

                                                            {this.state.fileTypes.map((fileType, fileTypeIndex) => (
                                                                <div
                                                                    key={fileTypeIndex}
                                                                    className={styles.CardRow}
                                                                >
                                                                    <FileLoader
                                                                        title={'Добавить'}
                                                                        label={fileType.name}
                                                                        required={true}
                                                                        accept={".jpg,.jpeg,.png,.pdf"}
                                                                        onChange={(e) => this.onChangeFiles(e, fileType.code, item.tempId)}
                                                                        files={(
                                                                            this.state.files.filter((file) => file.fileType === fileType.code && file.tempId === item.tempId).map((file, fileIndex) => (
                                                                                <File
                                                                                    key={fileIndex}
                                                                                    name={file.name}
                                                                                    href={`/api?method=Insurance&action=getFile&FileId=${file.fileId}&FileName=${file.name}`}
                                                                                    loading={file.tempIndex !== undefined}
                                                                                    onRemove={file.fileId ? () => this.deleteFile(file.fileId) : undefined }
                                                                                />
                                                                            )).reverse()
                                                                        )}
                                                                    />
                                                                </div>
                                                            ))}

                                                            {this.state.form.beneficiaries.length >= 2 && (
                                                                <Button
                                                                    variant="primaryOutline"
                                                                    size="lg"
                                                                    type="button"
                                                                    onClick={() => this.removeBeneficiary(itemIndex)}
                                                                    style={{ marginTop: 60 }}
                                                                >{ 'Удалить' }</Button>
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}

                                                {(this.state.form.beneficiaries || []).length < 4 && (
                                                    <div className={styles.Card}>
                                                        <Heading level={4}>{'Выгодоприобритатель'}</Heading>

                                                        <Button
                                                            variant="primary"
                                                            size="lg"
                                                            type="button"
                                                            onClick={() => this.addBeneficiary()}
                                                        >{ 'Добавить' }</Button>
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </div>

                                    <div className={styles.RightSide}>
                                        {this.state.requestId && (
                                            <div className={styles.Checkout}>
                                                <div className={styles.CheckoutWrapper}>
                                                    <div className={styles.CheckoutTitle}>
                                                        {this.activeSelection().code === 'Insured' && (
                                                            'Заявление об изменении застрахованных лиц'
                                                        )}

                                                        {this.activeSelection().code === 'Beneficiary' && (
                                                            'Заявление об изменении выгодоприобритателей'
                                                        )}
                                                    </div>
                                                    <div className={styles.CheckoutDate}>{`Черновик от ${moment(this.state.requestLastUpdatedAt).format('DD.MM.YYYY')}`}</div>
                                                    <div className={styles.CheckoutDescription}>После внесения изменений в нужные разделы заявления, его необходимо <b>подписать</b> и <b>отправить</b> </div>
                                                    <div className={styles.CheckoutEmail}>
                                                        <InputField
                                                            text="Укажите ваш email для получения уведомлений о смене статусов обработки заявления"
                                                            size="lg"
                                                            type="text"
                                                            placeholder="E-mail"
                                                            name="applicantEmail"
                                                            required={true}
                                                            value={this.state.form.applicantEmail}
                                                            error={this.state.applicantError.applicantEmail}
                                                            onChange={this.onChange}
                                                        />
                                                    </div>
                                                </div>

                                                <div className={styles.CheckoutButtons}>
                                                    <Button
                                                        href={`/api?method=datachange&action=previewfile&dataChangeRequestId=${this.state.requestId}&fileTypeCode=FT-1002`}
                                                        variant="primaryOutline"
                                                        size="lg"
                                                        type="button"
                                                    >
                                                        { 'Предпросмотр в PDF' }
                                                    </Button>

                                                    <Button
                                                        variant="danger"
                                                        size="lg"
                                                        type="button"
                                                        onClick={(e) => this.openConfirmationForm(e)}
                                                        disabled={this.submitDisabled()}
                                                    >{ 'Подписать и отправить' }</Button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Container>
                        </TopWrapper>
                    </Wrapper>
                </div>
            </AppLayout>
        );
    }
}

export default inject('store')(observer(ProfileUpdateRequest));
