import React, { useState } from 'react';
import cn from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import { AUTH_LOGOUT } from 'api';
import Container from 'components/Container';
import styles from './styles.module.scss';

const HeaderSecondNavBar = ({
    userName,
    claimsNotifCount = 0,
}) => {
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);

    const logout = () => {
        AUTH_LOGOUT().then(() => {
            window.location.reload();
        });
    };

    return (
        <div className={styles.HeaderSecondNavBar}>
            <Container fluid>
                <div
                    className={styles.HeaderSecondNavBarWrapper}
                    data-menu-opened={isOpen}
                >
                    <Link to="/profile" className={cn(styles.HeaderSecondNavBarLogo, styles.HeaderSecondNavBarLogoLIFE)}></Link>

                    {userName ? (
                        <div className={styles.HeaderSecondNavBarItems}>
                            <Link
                                to="/profile"
                                className={cn(
                                    styles.HeaderSecondNavBarItem,
                                    styles.HeaderSecondNavBarItemProfile,
                                    { [styles.HeaderSecondNavBarItemActive]: history.location.pathname === '/profile' },
                                )}>
                                { 'Кабинет' }
                            </Link>

                            <Link
                                to="/claims"
                                className={cn(
                                    styles.HeaderSecondNavBarItem,
                                    styles.HeaderSecondNavBarItemInsurance,
                                    { [styles.HeaderSecondNavBarItemActive]: history.location.pathname.split('/').includes('claims') },
                                )}
                                data-notif={claimsNotifCount}
                            >
                                { 'Мои страховые случаи' }
                            </Link>
                            <Link
                                to="/settings"
                                className={cn(
                                    styles.HeaderSecondNavBarItem,
                                    styles.HeaderSecondNavBarItemPerson,
                                    { [styles.HeaderSecondNavBarItemActive]: history.location.pathname === '/settings' },
                                )}>
                                { userName }
                            </Link>

                            <div
                                className={cn(
                                    styles.HeaderSecondNavBarItem,
                                    styles.HeaderSecondNavBarItemLogout,
                                )}
                                onClick={logout}
                            >{ 'Выход' }</div>
                        </div>
                    ) : (
                        <div className={styles.HeaderSecondNavBarItems}>
                            <Link
                                to="/login"
                                className={cn(
                                    styles.HeaderSecondNavBarItem,
                                    styles.HeaderSecondNavBarItemLogin,
                                    { [styles.HeaderSecondNavBarItemActive]: history.location.pathname === '/login' },
                                )}>
                                { 'Войти' }
                            </Link>
                        </div>
                    )}

                    <div
                        className={styles.HeaderSecondNavBarMenuToggler}
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <span />
                        <span />
                        <span />
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default HeaderSecondNavBar;
