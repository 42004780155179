import React from 'react';
import { inject, observer } from 'mobx-react';
import cn from 'classnames';
import moment from 'moment';
import Sticker from 'react-stickyfill';
import Switch from 'react-switch';
import AppLayout from 'layouts/AppLayout';
import Wrapper from 'components/Wrapper';
import TopWrapper from 'components/TopWrapper';
import ContentWrapper from 'components/ContentWrapper';
import Container from 'components/Container';
import Heading from 'components/Heading';
import Badge from 'components/Badge';
import TitledContent from 'components/TitledContent';
import BaseList, { BaseListItem, BaseListText } from 'components/BaseList';
import ToggleTabs, { ToggleTabsItem, ToggleTabsWrapper } from 'components/ToggleTabs';
import TextContent from 'components/TextContent';
import FadeTransition from 'components/FadeTransition';
import DocumentsCard from 'components/DocumentsCard';
import Button from 'components/Button';

import DetailsContentWrapper from 'components/_modules/wrappers/DetailsContentWrapper';

import InvestmentIncomeTab from 'components/_modules/tabs/InvestmentIncomeTab';
import AdditionalIncomeTab from 'components/_modules/tabs/AdditionalIncomeTab';
import SocialTaxDeductionTab from 'components/_modules/tabs/SocialTaxDeductionTab';

import BaseTable from 'components/BaseTable';

import RedemptionSumsTable from 'components/_modules/policy/RedemptionSumsTable';
import BasicAssetTable from 'components/_modules/policy/BasicAssetTable';
import EqualColumnsTable from 'components/_modules/policy/EqualColumnsTable';
import PaymentScheduleTable from 'components/_modules/policy/PaymentScheduleTable';

import {
    DATA_CHANGE_GET_LIST,
    PAYMENT_LIFE_SEND_SMS,
    PAYMENT_LIFE_VALIDATE_SMS,
    PAYMENT_LIFE_ENABLE_RECURRENT_PAYMENT,
    PAYMENT_LIFE_DISABLE_RECURRENT_PAYMENT,
    GET_PAYMENT_LINK,
} from 'api';

import get from 'lodash/get';

import ClinicList, { ClinicListItem, ClinicItem } from 'components/ClinicList';

import RecurrentPaymentModal from 'components/RecurrentPaymentModal';
import ConfirmSMSModal from 'components/RecurrentPaymentModal/ConfirmSMSModal';
import Modal from 'components/Modal';
import Preloader from 'components/Preloader/Preloader';
import styles from './styles.module.scss';

class PolicyPage extends React.Component {
    constructor(props) {
        super(props);

        this.store = props.store;

        this.state = {
            activeTab: 0,
            loading: true,
            claimList: [],
            transactionID: 0,
            link: null,
            taxDeductionSelections: {},
        };

        this.getTabByType = this.getTabByType.bind(this);
        this.updatePaymentLinkLife = this.updatePaymentLinkLife.bind(this);
        this.handleSwitchChange = this.handleSwitchChange.bind(this);
        this.handleSendSmsCode = this.handleSendSmsCode.bind(this);
        this.handleValidateSmsCode = this.handleValidateSmsCode.bind(this);
        this.handleResendSmsCode = this.handleResendSmsCode.bind(this);
    }

    componentDidMount() {
        document.title = this.store.policy.policy.productName || '';

        const { policyId } = this.props.match.params;

        this.store.policy.getPolicy(policyId).then(() => {
            document.title = this.store.policy.policy.productName || '';
            this.setState({
                ...this.state,
                loading: false,
            });
            this.store.policy.setIsRecurrentEnabled(this.store.policy.policy.allowRecurrentPayment);
        });

        DATA_CHANGE_GET_LIST(false, true)
            .then((data) => {
                let { result: { dataChangeRequests = [] } } = data;

                dataChangeRequests = dataChangeRequests.filter((item) => item.policy && item.policy.policyId === policyId);

                this.setState({
                    ...this.state,
                    claimList: dataChangeRequests,
                });
            });
    }

    componentWillUnmount() {
        this.store.policy.clearPolicy();
    }

    getTabByType(tab, tabIndex) {
        switch (tab.type) {
        case 'investmentIncome':
            return <div key={tabIndex} className={styles.TabWrapper}>
                <InvestmentIncomeTab
                    tabIndex={tabIndex}
                    items={tab.items}
                    policyId={this.props.match.params.policyId}/>
            </div>;
        case 'additionalIncome':
            return <div key={tabIndex} className={styles.TabWrapper}>
                <AdditionalIncomeTab
                    tabIndex={tabIndex}
                    items={tab.items}/>
            </div>;
        case 'socialTaxDeduction':
            return this.store.policy.policy.hasSocialTaxDeduction
                ? <div key={tabIndex} className={styles.TabWrapper}>
                    <SocialTaxDeductionTab
                        tabIndex={tabIndex}
                        items={tab.items}
                        policyId={this.props.match.params.policyId}
                    />
                </div>
                : <div key={tabIndex}></div>;
        default:
            return <div key={tabIndex}></div>;
        }
    }

    handleSwitchChange(checked) {
        this.store.policy.setSmsCode('');
        if (checked === true) {
            this.store.policy.setIsShowRecurrentPaymentModal(true);
        } else {
            this.store.policy.setLoading('sendDisableSms', true);
            PAYMENT_LIFE_SEND_SMS(this.store.profile.user.phone).then(() => {
                this.store.policy.setLoading('sendDisableSms', false);
                this.store.policy.setIsShowConfirmDisableModal(true);
            }).catch((error) => {
                this.store.policy.setLoading('sendDisableSms', false);
                this.store.policy.setMessage('title', 'Ошибка');
                this.store.policy.setMessage('body', error.data.message);
                this.store.policy.setIsShowErrorModal(true);
            });
        }
    }

    headingBadgeData() {
        const { policy } = this.store.policy;

        if (policy.status === 'PolicyStatus_Archival' || policy.status === 'PolicyStatus_Terminated' || policy.status === 'PolicyStatus_Completed' || policy.status === 'PolicyStatus_Annulated') {
            return {
                text: 'В архиве',
                color: '#828a9f',
            };
        }

        return {
            text: 'Активный',
            color: '#95c11f',
        };
    }

    handleSendSmsCode() {
        this.store.policy.setLoading('sendEnableSms', true);
        PAYMENT_LIFE_SEND_SMS(this.store.profile.user.phone).then(() => {
            this.store.policy.setLoading('sendEnableSms', false);
            this.store.policy.setIsShowConfirmEnableModal(true);
        }).catch((error) => {
            this.store.policy.setLoading('sendEnableSms', false);
            this.store.policy.setMessage('title', 'Ошибка');
            this.store.policy.setMessage('body', error.data.message);
            this.store.policy.setIsShowErrorModal(true);
        });
    }

    handleValidateSmsCode(actionType) {
        this.store.policy.setLoading('validateSms', true);
        PAYMENT_LIFE_VALIDATE_SMS(this.store.policy.smsCode, actionType, this.store.profile.user.phone).then(({ result }) => {
            if (actionType === 'Подтверждение включения автоплатежа') {
                PAYMENT_LIFE_ENABLE_RECURRENT_PAYMENT(this.props.match.params.policyId, result).then(() => {
                    this.store.policy.setLoading('validateSms', false);
                    this.store.policy.setIsShowRecurrentPaymentModal(false);
                    this.store.policy.setIsShowConfirmEnableModal(false);
                    let paymentDate = null;
                    if (this.store.policy.policy.tabs.find((tab) => tab.title === 'График платежей')) {
                        this.store.policy.policy.tabs.find((tab) => tab.title === 'График платежей').items.find((item) => item.title === 'График платежей').years.forEach((year) => {
                            Object.keys(year).forEach((yearKey) => {
                                for (let rowKey = 0; rowKey < year[yearKey].rows.length; rowKey++) {
                                    if (get(year[yearKey], `rows[${rowKey}].type`) && year[yearKey].rows[rowKey].type === 'current_payment') {
                                        if (get(year[yearKey], `rows[${rowKey}].expectedPaymentDate`)) {
                                            paymentDate = year[yearKey].rows[rowKey].expectedPaymentDate;
                                        }
                                    }
                                }
                            });
                        });
                    }
                    GET_PAYMENT_LINK(this.props.match.params.policyId, moment(paymentDate, 'DD.MM.YYYY').format('YYYY-MM-DD')).then(({ paymentLink }) => {
                        window.open(paymentLink, '_blank');
                        this.store.policy.setMessage('title', 'Подтверждение произведено');
                        this.store.policy.setMessage('body', <p>Подтверждение успешно произведено. <br/>Для включения Автоплатежа необходимо произвести оплату в течение 1 часа.</p>);
                        this.store.policy.setIsShowSuccessModal(true);
                        this.store.policy.setIsRecurrentEnabled(true);
                    }).catch((error) => {
                        this.store.policy.setMessage('title', 'Ошибка');
                        this.store.policy.setMessage('body', error.data.message);
                        this.store.policy.setIsShowErrorModal(true);
                    });
                }).catch((error) => {
                    this.store.policy.setMessage('title', 'Ошибка');
                    this.store.policy.setMessage('body', error.data.message);
                    this.store.policy.setIsShowErrorModal(true);
                });
            } else if (actionType === 'Подтверждение отключения автоплатежа') {
                PAYMENT_LIFE_DISABLE_RECURRENT_PAYMENT(this.props.match.params.policyId, result).then(() => {
                    this.store.policy.setLoading('validateSms', false);
                    this.store.policy.setIsShowRecurrentPaymentModal(false);
                    this.store.policy.setIsShowConfirmDisableModal(false);
                    this.store.policy.setMessage('title', 'Автоплатеж отключен');
                    this.store.policy.setMessage('body', <p>Отключение успешно произведено.</p>);
                    this.store.policy.setIsShowSuccessModal(true);
                    this.store.policy.setIsRecurrentEnabled(false);
                }).catch((error) => {
                    this.store.policy.setMessage('title', 'Ошибка');
                    this.store.policy.setMessage('body', error.data.message);
                    this.store.policy.setIsShowErrorModal(true);
                });
            } else {
                this.store.policy.setLoading('validateSms', false);
                this.store.policy.setMessage('title', 'Ошибка');
                this.store.policy.setMessage('body', 'Неправильно указан тип действия');
                this.store.policy.setIsShowErrorModal(true);
            }
        }).catch((error) => {
            this.store.policy.setLoading('validateSms', false);
            this.store.policy.setMessage('title', 'Ошибка');
            this.store.policy.setMessage('body', error.data.message);
            this.store.policy.setIsShowErrorModal(true);
        });
    }

    handleResendSmsCode() {
        this.store.policy.setLoading('resendSms', true);
        PAYMENT_LIFE_SEND_SMS(this.store.profile.user.phone).then(() => {
            this.store.policy.setLoading('resendSms', false);
        }).catch((error) => {
            this.store.policy.setLoading('resendSms', false);
            this.store.policy.setMessage('title', 'Ошибка');
            this.store.policy.setMessage('body', error.data.message);
            this.store.policy.setIsShowErrorModal(true);
        });
    }

    updatePaymentLinkLife(paymentLink, yearIndex, rowIndex) {
        this.store.policy.updatePaymentLinkLife(paymentLink, yearIndex, rowIndex);
    }

    transformLink(url) {
        const indexOfUpload = url.indexOf('upload');
        return url.substring(indexOfUpload)
    }

    render() {
        const policyFullDate = `${moment(this.store.policy.policy.contractBeginDate).format('DD.MM.YYYY')} —— ${moment(this.store.policy.policy.contractEndDate).format('DD.MM.YYYY')}`;
        return (
            <FadeTransition loading={this.state.loading}>
                <AppLayout>
                    <RecurrentPaymentModal
                        isShow={this.store.policy.isShowRecurrentPaymentModal}
                        handleClose={() => this.store.policy.setIsShowRecurrentPaymentModal(false)}
                        handleSendSmsCode={this.handleSendSmsCode}
                        loading={this.store.policy.loading.sendEnableSms}
                    />
                    <ConfirmSMSModal
                        title={ 'Подтвердите включение автоплатежа' }
                        isShow={this.store.policy.isShowConfirmEnableModal}
                        actionType={'Подтверждение включения автоплатежа'}
                        smsCode={this.store.policy.smsCode}
                        onChangeSmsCode={(e) => this.store.policy.setSmsCode(e.target.value)}
                        handleValidateSmsCode={this.handleValidateSmsCode}
                        loadingValidate={this.store.policy.loading.validateSms}
                        handleResendSmsCode={this.handleResendSmsCode}
                        loadingResend={this.store.policy.loading.resendSms}
                        handleClose={() => this.store.policy.setIsShowConfirmEnableModal(false)}
                    />
                    <ConfirmSMSModal
                        title={ 'Подтвердите отключение автоплатежа' }
                        isShow={this.store.policy.isShowConfirmDisableModal}
                        actionType={'Подтверждение отключения автоплатежа'}
                        smsCode={this.store.policy.smsCode}
                        onChangeSmsCode={(e) => this.store.policy.setSmsCode(e.target.value)}
                        handleValidateSmsCode={this.handleValidateSmsCode}
                        loadingValidate={this.store.policy.loading.validateSms}
                        handleResendSmsCode={this.handleResendSmsCode}
                        loadingResend={this.store.policy.loading.resendSms}
                        handleClose={() => this.store.policy.setIsShowConfirmDisableModal(false)}
                    />
                    {this.store.policy.isShowErrorModal
                        && <Modal
                            title={this.store.policy.message.title}
                            body={this.store.policy.message.body}
                            footer={
                                <Button
                                    onClick={() => this.store.policy.setIsShowErrorModal(false)}
                                    style={{ width: '100%' }}
                                    size="lg"
                                    variant="primary">{ 'Продолжить' }</Button>
                            }
                            handleClose={() => this.store.policy.setIsShowErrorModal(false)} />
                    }
                    {this.store.policy.isShowSuccessModal
                        && <Modal
                            title={this.store.policy.message.title}
                            body={this.store.policy.message.body}
                            footer={
                                <Button
                                    onClick={() => this.store.policy.setIsShowSuccessModal(false)}
                                    style={{ width: '100%' }}
                                    size="lg"
                                    variant="primary">{ 'Вернуться на страницу' }</Button>
                            }
                            handleClose={() => this.store.policy.setIsShowSuccessModal(false)} />
                    }
                    {Object.keys(this.store.policy.policy).length !== 0 && (
                        <Wrapper>
                            <TopWrapper background={`/${this.transformLink(this.store.policy.policy.backgroundUrl)}`}>
                                <Container fluid>
                                    <Heading
                                        level="2"
                                        color="#FFFFFF">
                                        <span style={{ marginRight: 8 }}>{this.store.policy.policy.productName}</span>
                                        <Badge style={{
                                            marginLeft: 0,
                                            background: this.headingBadgeData().color,
                                        }}>{this.headingBadgeData().text}</Badge>
                                    </Heading>

                                    <div className={styles.SideWrapper} style={{ padding: '50px 0 65px' }}>
                                        <div className={styles.SideWrapperInner}>
                                            <div className={styles.TitledContentWrapper}>
                                                <TitledContent
                                                    title={'Номер полиса'}
                                                    content={this.store.policy.policy.policyNumber}
                                                    color={'#FFFFFF'}/>
                                                <TitledContent
                                                    title={'Срок действия'}
                                                    content={policyFullDate}
                                                    color={'#FFFFFF'}/>
                                            </div>
                                        </div>

                                        <div
                                            className={cn(styles.SideWrapper, styles.TopSideWrapper)}
                                            data-has-documents={true}
                                        >
                                            <div className={styles.TopButtonsWrapper}>
                                                {this.store.policy.policy.canCreateDataChangeRequest && (
                                                    <Button
                                                        to={{ pathname: `/profile/policy/${this.props.match.params.policyId}/update` }}
                                                        style={{ margin: 0 }}
                                                        variant="whiteOutline"
                                                        size="lg"
                                                        type="button">
                                                        {'Подать заявление по договору страхования'}
                                                    </Button>
                                                )}

                                                {this.store.policy.policy.onlineDoctorUrl && (
                                                    <Button
                                                        href={this.store.policy.policy.onlineDoctorUrl}
                                                        variant="primary"
                                                        size="lg"
                                                        type="button">
                                                        {'Связаться с доктором'}
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Container>
                            </TopWrapper>
                            <ContentWrapper>
                                <ToggleTabsWrapper>
                                    <Container
                                        fluid
                                        position={null}>
                                        <Sticker>
                                            <div className={styles.TopDocumentsCardWrapper}>
                                                <div className={styles.TopDocumentsCardWrapperInner}>
                                                    <div className={styles.TopDocumentsCardWrapperInnerContainer}>
                                                        <div className={styles.TopDocumentsCardWrapperInnerSticky}>
                                                            <DocumentsCard
                                                                documents={this.store.policy.policy.documents}
                                                                isAdmin={this.store.profile.user.isAdmin}
                                                                canCreateLossClaimForm = {this.store.policy.policy.canCreateLossClaimForm}
                                                                canCreateMedicalHelpForm = {this.store.policy.policy.canCreateMedicalHelpForm}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Sticker>

                                        <DetailsContentWrapper padding="0">
                                            <ToggleTabs>
                                                {this.store.policy.policy.tabs && this.store.policy.policy.tabs.map((item, index) => (
                                                    <ToggleTabsItem
                                                        key={index}
                                                        active={this.state.activeTab === index}
                                                        onClick={() => this.setState({
                                                            ...this.state,
                                                            activeTab: index,
                                                        })}>{item.title}</ToggleTabsItem>
                                                ))}
                                            </ToggleTabs>
                                        </DetailsContentWrapper>
                                    </Container>
                                </ToggleTabsWrapper>
                                {this.store.policy.policy.tabs && this.store.policy.policy.tabs.map((tab, tabIndex) => (
                                    this.state.activeTab === tabIndex && (
                                        (tab.type === 'socialTaxDeduction' || tab.type === 'investmentIncome' || tab.type === 'additionalIncome')
                                            ? this.getTabByType(tab, tabIndex)
                                            : <div
                                                key={tabIndex}
                                                className={styles.TabWrapper}>
                                                {this.store.policy.loading.sendDisableSms
                                                    ? <DetailsContentWrapper>
                                                        <div className={styles.PreloaderWrapper}>
                                                            <Preloader embedded/>
                                                        </div>
                                                    </DetailsContentWrapper>
                                                    : tab.items.map((item, itemIndex) => (
                                                        <React.Fragment key={itemIndex}>
                                                            {(item.type === 'tabHeader' && (tab.title === 'График платежей' ? (
                                                                <DetailsContentWrapper
                                                                    padding="55px 0 75px"
                                                                    backgroundColor="#F8F8FA"
                                                                >
                                                                    {this.store.policy.policy.canEnableRecurrentPayment
                                                                        ? (<div
                                                                            className={cn(styles.TitledContentWrapper, styles.TitledContentWrapper_RecurrentPayment)}>
                                                                            {item.values.map((value, valueIndex) => (
                                                                                <TitledContent
                                                                                    key={valueIndex}
                                                                                    title={value.title}
                                                                                    content={value.subTitle}/>
                                                                            ))}
                                                                            <TitledContent
                                                                                title={'Автоплатеж'}
                                                                                content={
                                                                                    <div className={styles.TitledContentSwitch}>
                                                                                        <Switch
                                                                                            checked={this.store.policy.isRecurrentEnabled}
                                                                                            onChange={this.handleSwitchChange}
                                                                                            handleDiameter={16}
                                                                                            offColor="#6B7180"
                                                                                            onColor="#06408C"
                                                                                            offHandleColor="#fff"
                                                                                            onHandleColor="#fff"
                                                                                            height={20}
                                                                                            width={44}
                                                                                            checkedIcon={<div></div>}
                                                                                            uncheckedIcon={<div></div>}
                                                                                        />
                                                                                        <span className={cn(styles.TitledContentSwitch__Text, { [styles.TitledContentSwitch__Text_Checked]: this.store.policy.isRecurrentEnabled })}>
                                                                                            {this.store.policy.isRecurrentEnabled ? 'Автоплатеж включен' : 'Автоплатеж выключен' }
                                                                                        </span>
                                                                                    </div>
                                                                                }/>
                                                                        </div>)
                                                                        : (
                                                                            <div className={styles.TitledContentWrapper}>
                                                                                {item.values.map((value, valueIndex) => (
                                                                                    <TitledContent
                                                                                        key={valueIndex}
                                                                                        title={value.title}
                                                                                        content={value.subTitle}/>
                                                                                ))}
                                                                            </div>
                                                                        )}
                                                                </DetailsContentWrapper>)
                                                                : (
                                                                    <DetailsContentWrapper
                                                                        padding="55px 0 75px"
                                                                        backgroundColor="#F8F8FA"
                                                                    >
                                                                        <div className={styles.TitledContentWrapper}>
                                                                            {item.values.map((value, valueIndex) => (
                                                                                <TitledContent
                                                                                    key={valueIndex}
                                                                                    title={value.title}
                                                                                    content={value.subTitle}/>
                                                                            ))}
                                                                        </div>
                                                                    </DetailsContentWrapper>)))}

                                                            {item.type === 'list' && (
                                                                <DetailsContentWrapper
                                                                    isToggle={item.closed}
                                                                    title={item.title}
                                                                >
                                                                    <BaseList className={styles.InfoList}>
                                                                        {item.values.map((value, valueIndex) => (
                                                                            <BaseListItem
                                                                                key={valueIndex}
                                                                                title={value.title}>
                                                                                {value.values.map((nestedValue, nestedValueIndex) => (
                                                                                    <BaseListText key={nestedValueIndex}
                                                                                        text={nestedValue.value}
                                                                                        subText={nestedValue.subValue}/>
                                                                                ))}
                                                                            </BaseListItem>
                                                                        ))}
                                                                    </BaseList>

                                                                </DetailsContentWrapper>
                                                            )}

                                                            {item.type === 'clinic_list' && (
                                                                <DetailsContentWrapper
                                                                    isToggle={item.closed}
                                                                    title={item.title}
                                                                >
                                                                    <ClinicList className={styles.InfoList}>
                                                                        {item.values.$mobx.values.map((value, valueIndex) => (
                                                                            <ClinicListItem
                                                                                key={valueIndex}
                                                                                title={value.title}>
                                                                                {value.values.$mobx.values.map((nestedValue, nestedValueIndex) => (
                                                                                    <ClinicItem key={nestedValueIndex}
                                                                                        text={nestedValue}/>
                                                                                ))}
                                                                            </ClinicListItem>
                                                                        ))}
                                                                    </ClinicList>

                                                                </DetailsContentWrapper>
                                                            )}

                                                            {item.type === 'content' && (
                                                                <DetailsContentWrapper title={item.title}>
                                                                    {item.values.map((value, valueIndex) => (
                                                                        <TextContent key={valueIndex}
                                                                            dangerouslySetInnerHTML={{ __html: value.value }}/>
                                                                    ))}
                                                                </DetailsContentWrapper>
                                                            )}

                                                            {(item.type === 'redemption_sums_table' || item.type_table === 'redemption_sums_table') && (
                                                                <DetailsContentWrapper
                                                                    title={item.title}
                                                                    isToggle={item.closed}
                                                                >
                                                                    <RedemptionSumsTable rows={item.rows}/>
                                                                </DetailsContentWrapper>
                                                            )}

                                                            {(item.type === 'basic_asset_table' || item.type_table === 'basic_asset_table') && (
                                                                <DetailsContentWrapper
                                                                    title={item.title}
                                                                    isToggle={item.closed}
                                                                >
                                                                    <BasicAssetTable rows={item.rows}/>
                                                                </DetailsContentWrapper>
                                                            )}

                                                            {(item.type === 'equal_columns_table' || item.type_table === 'equal_columns_table') && (
                                                                <DetailsContentWrapper
                                                                    title={item.title}
                                                                    isToggle={item.closed}
                                                                >
                                                                    <EqualColumnsTable rows={item.rows}/>
                                                                </DetailsContentWrapper>
                                                            )}

                                                            {(item.type === 'payment_schedule_table' || item.type_table === 'payment_schedule_table' || item.table_type === 'payment_schedule_table') && (
                                                                <DetailsContentWrapper
                                                                    title={item.title}
                                                                    isToggle={item.closed}
                                                                >
                                                                    <PaymentScheduleTable
                                                                        years={item.years}
                                                                        policyId={this.props.match.params.policyId}
                                                                        isRecurrentEnabled={this.store.policy.isRecurrentEnabled}
                                                                        updatePaymentLink={this.updatePaymentLinkLife}
                                                                    />
                                                                </DetailsContentWrapper>
                                                            )}
                                                        </React.Fragment>
                                                    ))}
                                            </div>
                                    )
                                ))}

                                {this.state.claimList.length !== 0 && (
                                    <div style={{ backgroundColor: '#F8F8FA' }}>
                                        <Container fluid>
                                            <div className={styles.SideWrapper}>
                                                <div className={styles.SideWrapperInner}>
                                                    <div style={{ marginTop: 85, marginBottom: 75 }}>
                                                        <Heading level="3">{'История изменений'}</Heading>

                                                        <BaseTable className={styles.ClaimTable}>
                                                            <tbody>
                                                                {this.state.claimList.map((item, itemIndex) => (
                                                                    <tr
                                                                        key={itemIndex}
                                                                        data-status={item.status.code}
                                                                    >
                                                                        <td data-col="date">{moment(item.dateAndTime).format('DD.MM.YYYY')}</td>

                                                                        <td data-col="name">
                                                                            <span>{item.name}</span>
                                                                            <span data-rel="subTitle"></span>
                                                                        </td>

                                                                        <td data-col="status">{item.status.name}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </BaseTable>
                                                    </div>
                                                </div>
                                            </div>
                                        </Container>
                                    </div>
                                )}
                            </ContentWrapper>
                        </Wrapper>
                    )}
                </AppLayout>
            </FadeTransition>
        );
    }
}

export default inject('store')(observer(PolicyPage));
