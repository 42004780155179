import { observable, action, decorate } from 'mobx';

class AppModule {
    companyCode = null;

    mainLayoutBackgroundUrl = null;

    modalData = null;

    isShowVerifyEmailModal = false;

    constructor() {
        this.setCompanyCode('Life');
    }

    setCompanyCode(value) {
        this.companyCode = value;
    }

    setMainLayoutBackgroundUrl(value) {
        this.mainLayoutBackgroundUrl = value;
    }

    setModalData(data) {
        this.modalData = data;
    }

    setIsShowVerifyEmailModal(data) {
        this.isShowVerifyEmailModal = data;
    }
}

decorate(AppModule, {
    companyCode: observable,
    mainLayoutBackgroundUrl: observable,
    modalData: observable,
    isShowVerifyEmailModal: observable,
    setCompanyCode: action.bound,
    setMainLayoutBackgroundUrl: action.bound,
    setModalData: action.bound,
    setIsShowVerifyEmailModal: action.bound,
});

export default AppModule;
