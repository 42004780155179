import React, { PureComponent } from 'react';
import { inject, observer } from 'mobx-react';
import AppLayout from 'layouts/AppLayout';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
import TopWrapper from '../../../components/TopWrapper';
import Wrapper from '../../../components/Wrapper';
import Container from '../../../components/Container';

class PaymentPageSuccess extends PureComponent {
    constructor(props) {
        super(props);
        this.store = props.store;
        this.state = {
        };
    }

    render() {
        return (
            <AppLayout
                hasBackground={false}
                noVerifyEmailModal
            >
                <div>
                    <Wrapper>
                        <TopWrapper>
                            <Container fluid>
                                <div className={styles.SuccessPageText}>
                                    <div className={styles.SuccessPageTitle}>Оплата прошла успешно!</div>
                                    <Link
                                        to="/profile"
                                        className={styles.SuccessPageReturnLink}>
                                        { 'Вернуться на главную' }
                                    </Link>
                                </div>
                            </Container>
                        </TopWrapper>
                    </Wrapper>
                </div>
            </AppLayout>
        );
    }
}

export default inject('store')(observer(PaymentPageSuccess));
