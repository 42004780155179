import React from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Container from 'components/Container';
import LoginWrapper from 'components/LoginWrapper';
import LoginCard from 'components/LoginCard';
import MaskedInput from 'components/MaskedInputField';
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Cookies from 'universal-cookie';
import { InvisibleSmartCaptcha } from '@yandex/smart-captcha';
import ConfirmAgreementModal from '../components/ConfirmAgreementModal';
import Preloader from '../components/Preloader/Preloader';
import { CHECK_CAPTCHA } from '../api';
import { getCaptchaSiteKey, isTestEnvironment, isProdEnvironmentInlife, isProdEnvironmentSCB } from '../helpers/helpers';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        this.store = props.store;

        this.state = {
            title: 'Авторизация',
            loadingConfirm: false,
            visibleCaptcha: false,
        };

        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.handleValidateSMS = this.handleValidateSMS.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.handleSignIn = this.handleSignIn.bind(this);
        this.handleSetCaptchaModalVisible = this.handleSetCaptchaModalVisible.bind(this);
        this.handleChallengeHidden = this.handleChallengeHidden.bind(this);
        this.handleCheckTokenAndSignIn = this.handleCheckTokenAndSignIn.bind(this);
    }

    handleCloseModal() {
        this.props.history.push('/login');
        this.store.login.setIsShowConfirmModal(false);
    }

    handleSignIn(e) {
        e.preventDefault();
        this.store.login.signIn();
    }

    handleSetCaptchaModalVisible(e) {
        e.preventDefault();
        this.setState({ visibleCaptcha: true });
    }

    handleChallengeHidden() {
        this.setState({ visibleCaptcha: false });
    }

    handleCheckTokenAndSignIn(token) {
        CHECK_CAPTCHA(token).then(() => {
            this.store.login.signIn();
        });
    }

    handleValidateSMS(e) {
        e.preventDefault();

        this.store.login.validateSMS()
            .then(async () => {
                if (!this.store.login.userFound) {
                    this.props.history.push('/register');
                } else if (this.store.login.userFound && !this.store.login.userUnique) {
                    this.props.history.push('/register/confirmation');
                } else if (this.store.login.userFound && this.store.login.userUnique) {
                    await this.store.profile.getClientInfo();

                    this.props.history.push('/profile');
                }
            });
    }

    handleReset() {
        this.store.login.reset();
    }

    componentDidMount() {
        document.title = this.state.title;

        // Кроссдоменная авторизация

        const key = window.location.search.split('?')[1];
        if (key !== undefined) {
            const cookies = new Cookies();
            cookies.set('RS_KEY', key, { path: '/' });
        }

        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            const eqPos = cookie.indexOf('=');
            const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            if (name.indexOf('waUserId') !== -1) {
                document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
            }
        }
        document.cookie = 'waSessionId =; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';

        this.handleReset();

        // Переход на модальное окно согласия с условиями при наличии кода в ссылке

        const path = new URL(window.location.href).pathname;
        const acceptCode = path.split('/')[2];
        if (acceptCode !== null && acceptCode !== undefined && acceptCode !== '' && acceptCode.length === 8) {
            this.setState({ loadingConfirm: true });
            this.store.login.setAcceptCode(acceptCode);
            this.store.login.acceptConditions()
                .then(async () => {
                    this.setState({ loadingConfirm: false });
                    if (this.store.login.modalData.success) {
                        this.store.login.setIsShowConfirmModal(true);
                        const { phone } = this.store.login.modalData;
                        this.store.login.onFieldChange('phone', `+${phone.substring(0, 1)} ${phone.substring(1, 4)}-${phone.substring(4, 7)}-${phone.substring(7, 9)}-${phone.substring(9, 11)}`);
                    } else {
                        this.props.history.push('/login');
                    }
                });
        } else {
            this.props.history.push('/login');
        }
    }

    render() {
        const isCheckCaptcha = isTestEnvironment() || isProdEnvironmentInlife() || isProdEnvironmentSCB();
        return (
            <React.Fragment>
                {this.state.loadingConfirm
                    ? <Preloader/>
                    : <ConfirmAgreementModal
                        isShow={this.store.login.isShowConfirmModal}
                        handleClose={this.handleCloseModal}
                        text={this.store.login.modalData.text}
                    />}

                <LoginWrapper>
                    <Container fluid>
                        {isCheckCaptcha
                            && <InvisibleSmartCaptcha
                                sitekey={getCaptchaSiteKey()}
                                onSuccess={(token) => this.handleCheckTokenAndSignIn(token)}
                                onChallengeHidden={this.handleChallengeHidden}
                                visible={this.state.visibleCaptcha}
                            />
                        }
                        {this.store.login.stage === 'phone' && (
                            <LoginCard title={'Вход в личный кабинет'}>
                                <form onSubmit={isCheckCaptcha ? this.handleSetCaptchaModalVisible : this.handleSignIn}>
                                    <MaskedInput
                                        autoFocus={true}
                                        mask={['+', '7', ' ', /[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
                                        label={'Введите номер телефона'}
                                        error={this.store.login.form.fields.phone.error}
                                        size="lg"
                                        type="tel"
                                        placeholder="+7 _ _ _ - _ _ _ - _ _ - _ _"
                                        name="phone"
                                        value={this.store.login.form.fields.phone.value}
                                        onChange={(e) => this.store.login.onFieldChange(e.target.name, e.target.value)}/>
                                    <Button
                                        style={{ width: '100%', marginTop: 30 }}
                                        variant="primary"
                                        size="lg"
                                        type="submit"
                                        disabled={this.store.login.form.meta.submitDisabled()}
                                        loading={this.store.login.form.meta.loading.SEND_SMS_CODE}>
                                        {'Продолжить'}
                                    </Button>
                                </form>
                            </LoginCard>
                        )}

                        {this.store.login.stage === 'code' && (
                            <LoginCard title={'Введите код'}>
                                <form onSubmit={this.handleValidateSMS}>
                                    <MaskedInput
                                        autoFocus={true}
                                        mask={[/\d/, /\d/, /\d/, /\d/, /\d/]}
                                        text={
                                            <React.Fragment>
                                                Вам на номер <b>{this.store.login.form.fields.phone.value}</b> выслано
                                                СМС с кодом подтверждения
                                            </React.Fragment>
                                        }
                                        error={this.store.login.form.fields.code.error || this.store.login.form.fields.phone.error}
                                        size="lg"
                                        type="text"
                                        placeholder="00000"
                                        name="code"
                                        value={this.store.login.form.fields.code.value}
                                        onChange={(e) => this.store.login.onFieldChange(e.target.name, e.target.value)}/>

                                    <Button
                                        style={{ marginTop: 10 }}
                                        buttonType="text"
                                        variant={!this.store.login.form.meta.codeSended ? 'primary' : 'secondary'}
                                        size="sm"
                                        onClick={isCheckCaptcha ? this.handleSetCaptchaModalVisible : this.handleSignIn}
                                        disabled={this.store.login.form.meta.codeSended || this.store.login.form.meta.loading.SEND_SMS_CODE}
                                        loading={this.store.login.form.meta.loading.SEND_SMS_CODE}
                                    >
                                        {!this.store.login.form.meta.codeSended ? 'Выслать еще раз' : `Выслать код повторно можно через ${this.store.login.form.meta.timeToResend} сек`}
                                    </Button>

                                    {!this.store.login.form.meta.userAgreementConfirmed && (
                                        <Checkbox
                                            style={{ marginTop: 35 }}
                                            name="userAgreement"
                                            checked={this.store.login.form.fields.userAgreement.value}
                                            onChange={(e) => this.store.login.onFieldChange(e.target.name, e.target.checked)}>
                                            Я принимаю условия
                                            <br/>
                                            <a
                                                href={'/upload/docs/pol_sogl_sovcomlife.pdf'}
                                                target="_blank"
                                                rel="noopener noreferrer">пользовательского соглашения</a>
                                        </Checkbox>
                                    )}

                                    <Button
                                        style={{ width: '100%', marginTop: 30 }}
                                        variant="primary"
                                        size="lg"
                                        type="submit"
                                        disabled={this.store.login.form.meta.submitDisabled()}
                                        loading={this.store.login.form.meta.loading.CHECK_SMS_CODE}>{'Войти'}</Button>

                                    <div style={{ textAlign: 'center' }}>
                                        <Button
                                            style={{ marginTop: 15 }}
                                            buttonType="text"
                                            variant="primary"
                                            size="sm"
                                            type="button"
                                            onClick={this.handleReset}>{'Вернуться'}</Button>
                                    </div>
                                </form>
                            </LoginCard>
                        )}
                    </Container>
                </LoginWrapper>
            </React.Fragment>
        );
    }
}

export default withRouter(inject('store')(observer(LoginPage)));
