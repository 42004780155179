import React from 'react';
import moment from 'moment';
import File from 'components/File';
import FileLoader from 'components/FileLoader';
import InputField from 'components/InputField';
import Button from 'components/Button';
import TextareaField from '../TextareaField';
import styles from './styles.module.scss';

const getMark = (claim) => {
    if (claim.newMessage || claim.needsReply) {
        return 'attention';
    }
    return 'success';
};

const ClaimStages = ({
    claims = [],
    curatorName = '',
    onAddEvent,
    acceptExtensions,
    onChangeFile,
    onDeleteFile,
    onChangeClaimField,
    onSubmitFormNewMessage,
    onDeleteFormNewMessage,
    submitFormNewMessageLoading,
}) => (
    <div className={styles.ClaimStages}>
        {claims.map((claim, claimIndex) => (
            <div
                key={claimIndex}
                className={styles.ClaimStagesItem}
                data-mark={getMark(claim)}
            >
                <div className={styles.ClaimStagesItemDate}>{moment(claim.createdAt).format('DD.MM.YYYY, HH:mm')}</div>
                <div className={styles.ClaimStagesItemTitle}>{claim.status ? claim.status.name : claim.type.name}</div>
                <div className={styles.ClaimStagesItemContent}>
                    {claim.message && (
                        <>
                            {claim.type.code === 'LossClaimEventType_CompanyMessage' && (
                                <div className={styles.CuratorName}>
                                    {curatorName}
                                </div>
                            )
                            }
                            <div>
                                {claim.message}
                            </div>
                        </>
                    )}

                    {claim.files.length !== 0 && (
                        <div>
                            <b>Отправленные файлы:</b>

                            <div className={styles.ClaimStagesItemContentFiles}>
                                {claim.files.map((file, fileIndex) => (
                                    <File
                                        key={fileIndex}
                                        name={file.name}
                                        href={`/api?method=Insurance&action=getFile&FileId=${file.fileId}&FileName=${file.name}`}
                                    />
                                ))}
                            </div>
                        </div>
                    )}

                    {claim.needsReply && (
                        <div className={styles.ClaimStagesItemContentForm}>
                            <form onSubmit={(e) => onAddEvent(e, claimIndex)}>
                                <InputField
                                    size="lg"
                                    label="Уточнение"
                                    placeholder="Уточнение"
                                    value={claim.userMessage}
                                    onChange={(e) => onChangeClaimField(e, claimIndex, 'userMessage')}
                                />

                                {claim.fileTypes && claim.fileTypes.map((fileType, fileTypeIndex) => (
                                    <div
                                        key={fileTypeIndex}
                                        style={{ width: '100%', marginTop: 30 }}
                                    >
                                        <FileLoader
                                            title={'Добавить'}
                                            label={fileType.name}
                                            accept={acceptExtensions}
                                            onChange={(e) => onChangeFile(e, fileType.code, claimIndex)}
                                            files={(
                                                (claim.userFiles || []).filter((file) => file.fileType === fileType.code).map((file, fileIndex) => (
                                                    <File
                                                        key={fileIndex}
                                                        name={file.name}
                                                        onRemove={() => onDeleteFile(file.id, claimIndex)}
                                                    />
                                                )).reverse()
                                            )}
                                        />
                                    </div>
                                ))}

                                <div className={styles.ClaimStagesItemContentFormButton}>
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        size="lg"
                                    >
                                        {'Отправить'}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    )}

                    {claim.newMessage && (
                        <div className={styles.ClaimStagesItemContentForm}>
                            <form id="newMessageForm" onSubmit={(e) => onSubmitFormNewMessage(e, claimIndex)}>
                                <TextareaField
                                    rows="8"
                                    size="lg"
                                    label="Ваше сообщение"
                                    value={claim.userNewMessage}
                                    onChange={(e) => onChangeClaimField(e, claimIndex, 'userNewMessage')}
                                />
                                <div className={styles.ClaimStagesItemContentFormNewMessageButtons}>
                                    <Button
                                        type="button"
                                        buttonType="text"
                                        variant="secondary"
                                        size="lg"
                                        style={{ width: '40%' }}
                                        onClick={onDeleteFormNewMessage}
                                    >
                                        {'Отменить'}
                                    </Button>
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        size="lg"
                                        style={{ width: '40%' }}
                                        loading={submitFormNewMessageLoading}
                                    >
                                        {'Отправить'}
                                    </Button>
                                </div>
                            </form>
                        </div>
                    )}
                </div>
            </div>
        ))}
    </div>
);

export default ClaimStages;
