import React from 'react';
import { inject, observer } from 'mobx-react';
import BaseHeader from 'components/BaseHeader';
import Footer from 'components/Footer';

class BaseLayout extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const { children } = this.props;
        return (
            <React.Fragment>
                <BaseHeader/>
                { children }
                <Footer/>
            </React.Fragment>
        );
    }
}

export default inject('store')(observer(BaseLayout));
