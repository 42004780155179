import React, { PureComponent } from 'react';
import { inject, observer } from 'mobx-react';
import AppLayout from 'layouts/AppLayout';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
import TopWrapper from '../../../components/TopWrapper';
import Wrapper from '../../../components/Wrapper';
import Container from '../../../components/Container';

class PaymentPageFail extends PureComponent {
    constructor(props) {
        super(props);
        this.store = props.store;
        this.state = {
        };
    }

    render() {
        return (
            <AppLayout
                hasBackground={false}
                noVerifyEmailModal
            >
                <div>
                    <Wrapper>
                        <TopWrapper>
                            <Container fluid>
                                <div className={styles.FailPageText}>
                                    <div className={styles.FailPageTitle}>К сожалению, банк отклонил ваш платеж.</div>
                                    <div className={styles.FailPageSubtitle}>Пожалуйста, проверьте реквизиты и баланс. В случае
                                        повторения ситуации - свяжитесь с вашим банком для
                                        уточнения причины отказа.</div><Link
                                        to="/profile"
                                        className={styles.FailPageReturnLink}>
                                        { 'Вернуться на главную' }
                                    </Link>

                                </div>
                            </Container>
                        </TopWrapper>
                    </Wrapper>
                </div>
            </AppLayout>
        );
    }
}

export default inject('store')(observer(PaymentPageFail));
