import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import Container from 'components/Container';
import MenuBurger from 'components/MenuBurger';
import styles from './styles.module.scss';

const BaseHeader = () => (
    <div className={styles.BaseHeader}>
        <nav className={styles.BaseHeaderNav}>
            <Container fluid>
                <div className={styles.BaseHeaderNavContainer}>
                    <div className={styles.BaseHeaderNavContent}>
                        <a
                            href="https://sovcomlife.ru/"
                            target='_blank'
                            rel="noopener noreferrer"
                            className={cn(styles.BaseHeaderNavItem, styles.BaseHeaderReturnLink)}>
                            <span>{'Вернуться на сайт'}</span>
                        </a>
                    </div>
                    <div className={styles.BaseHeaderNavContent}>
                        <a
                            href='https://pyrus.sovcombank.ru/form/371527'
                            target='_blank'
                            rel="noopener noreferrer"
                            className={styles.BaseHeaderNavItem}
                            data-name="feedback">
                                Связаться с нами
                        </a>
                        <a
                            href={'tel:88005103510'}
                            className={styles.BaseHeaderNavPhone}>{'8 800 510-3-510'}
                        </a>
                        <span className={styles.BaseHeaderNavPhoneText}>
                            (будние дни с 8:00 до 19:00, Мск)
                        </span>
                        <a
                            href="https://sovcomlife.ru/support-centre/make-a-claim"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div className={styles.BaseHeaderNavSos}>{'SOS'}</div>
                        </a>
                    </div>
                </div>
            </Container>
        </nav>
        <nav className={styles.BaseHeaderNavSecond}>
            <Container fluid>
                <div className={styles.BaseHeaderNavSecondContainer}>
                    <a href={'/'}
                        className={cn(styles.BaseHeaderNavSecondLogo, styles.BaseHeaderNavSecondLogoLIFE)}> </a>
                    <div className={styles.BaseHeaderNavSecondContent}>
                        <div className={styles.BaseHeaderNavSecondLoginItem} data-active={true}>
                            <Link to="/login">
                                {'Войти'}
                            </Link>
                        </div>
                        <div className={styles.BaseHeaderNavSecondSearch}/>
                        <div className={styles.BaseHeaderNavSecondMenuToggle}>
                            <MenuBurger/>
                        </div>
                    </div>
                </div>
            </Container>
        </nav>
    </div>
);

export default BaseHeader;
