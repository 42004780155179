import React, { forwardRef } from 'react';
import { withRouter } from 'react-router-dom';
import Button from 'components/Button';
import cn from 'classnames';
import styles from './styles.module.scss';

const DocumentsCard = forwardRef(({
    style,
    history,
    match,
    documents,
    isAdmin,
    canCreateLossClaimForm,
    canCreateMedicalHelpForm,
}, ref) => {
    const isButtonVisible = canCreateLossClaimForm || canCreateMedicalHelpForm || (isAdmin === true && canCreateLossClaimForm === true);

    const redirectDMS = () => {
        const search = `?${Object.keys(match.params).map((key) => `${key}=${match.params[key]}`).join('&')}`;

        history.push({ pathname: '/claimDMS', search });
    };

    const redirect = () => {
        const search = `?${Object.keys(match.params).map((key) => `${key}=${match.params[key]}`).join('&')}`;

        history.push({ pathname: '/claim', search });
    };

    const redirectAdmin = () => {
        const search = `?${Object.keys(match.params).map((key) => `${key}=${match.params[key]}`).join('&')}`;

        history.push({ pathname: '/admin/claim', search });
    };

    return (
        <div
            ref={ref}
            className={styles.DocumentCard}
            style={{ ...style }}
        >
            {documents.length !== 0 && (
                <div className={styles.DocumentCardBody}>
                    {documents.map((document, documentIndex) => (
                        <div
                            key={documentIndex}
                            className={styles.DocumentCardItem}>
                            <div
                                className={styles.DocumentCardIcon}
                                data-format={document.format}/>
                            <div className={styles.DocumentCardContent}>
                                <a
                                    href={document.url.includes('method') ? `/api?${document.url}` : document.url }
                                    className={styles.DocumentCardTitle}
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >{document.name}</a>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            <div
                className={cn(styles.DocumentCardFooter, !isButtonVisible && styles.DocumentCardFooterHidden)}
                style={{ border: documents.length === 0 && 'none' }}
            >
                {canCreateLossClaimForm === true && (
                    <Button
                        type="button"
                        variant="danger"
                        size="lg"
                        className={styles.DocumentCardButton}
                        onClick={() => redirect()}
                    >
                        {'Заявить о страховом случае'}
                    </Button>
                )}

                {canCreateMedicalHelpForm === true && (
                    <Button
                        type="button"
                        variant="danger"
                        size="lg"
                        className={styles.DocumentCardButton}
                        onClick={() => redirectDMS()}
                    >
                        {'Получить медицинскую помощь'}
                    </Button>
                )}

                {isAdmin === true && canCreateLossClaimForm === true && (
                    <Button
                        type="button"
                        variant="danger"
                        size="lg"
                        className={styles.DocumentCardButton}
                        onClick={() => redirectAdmin()}
                    >
                        {'Заявить о страховом случае Администратор'}
                    </Button>
                )}
            </div>
        </div>
    );
});

export default withRouter(DocumentsCard);
