import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import AppLayout from 'layouts/AppLayout';
import Wrapper from 'components/Wrapper';
import TopWrapper from 'components/TopWrapper';
import Container from 'components/Container';
import Heading from 'components/Heading';
import styles from './styles.module.scss';
import FileLoader from '../../../components/FileLoader';
import Button from '../../../components/Button';
import { STATEMENT_LOSS_CLAIM_DATA, STATEMENT_NEW_MESSAGE } from '../../../api';
import File from '../../../components/File';
import Modal from '../../../components/Modal';

class AddDocumentsPage extends Component {
    constructor(props) {
        super(props);
        this.store = props.store;
        this.state = {
            title: 'Документы',
            claimId: this.props.match.params.claimId,
            fileTypes: [],
            filesToSend: [],
            loadingSendFiles: false,
            isShowSuccessModal: false,
        };
        this.onSubmitSendFiles = this.onSubmitSendFiles.bind(this);
    }

    index = 0;

    getFileTypes() {
        return STATEMENT_LOSS_CLAIM_DATA(this.props.match.params.claimId)
            .then((data) => {
                const { result: { fileTypes } } = data;

                this.setState({
                    ...this.state,
                    fileTypes,
                });
            });
    }

    onChangeFiles(e, type) {
        const files = [...e.target.files];
        const filesToSend = [...this.state.filesToSend];
        const filesError = [];

        e.target.value = null;

        for (let i = 0; i < files.length; i += 1) {
            const maxFileLength = 40;
            const maxFileSize = 20;
            const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;
            const file = files[i];
            const { size: fileSize, name: fileName } = file;
            const fileSizeMB = (fileSize / (1024 * 1024)).toFixed(2);

            const checkFileTypeLength = Boolean(filesToSend.filter((item) => item.fileType === type).length >= maxFileLength);
            const checkAllowedExtensions = allowedExtensions.exec(fileName);

            if (checkFileTypeLength) {
                filesError.push(`К загрузке допускается всего ${maxFileLength} файлов.`);

                break;
            } else if (!checkAllowedExtensions) {
                filesError.push(`Недопустимое расширение у файла ${fileName}`);
            } else if (fileSizeMB > maxFileSize) {
                filesError.push(`Размер файла ${fileName} превышает допустимые ${maxFileSize}мб.`);
            } else {
                filesToSend.push({
                    id: this.index,
                    name: fileName,
                    fileType: type,
                    file,
                });
                this.index++;

                this.setState({
                    ...this.state,
                    filesToSend,
                });
            }
        }

        if (filesError.length !== 0) {
            this.store.app.setModalData({
                title: 'Ошибка',
                body: filesError.join('\n'),
            });
        }
    }

    removeFile(id) {
        const filesToSend = [...this.state.filesToSend];
        const fileToRemoveIndex = filesToSend.findIndex((item) => item.id === id);
        filesToSend.splice(fileToRemoveIndex, 1);
        this.setState({
            ...this.state,
            filesToSend,
        });
    }

    onSubmitSendFiles(e) {
        e.preventDefault();
        if (this.state.filesToSend.length > 0) {
            this.setState({
                ...this.state,
                loadingSendFiles: true,
            });

            STATEMENT_NEW_MESSAGE(this.state.claimId, '', this.state.filesToSend).then(() => {
                this.setState({
                    ...this.state,
                    filesToSend: [],
                    isShowSuccessModal: true,
                });
            }).catch((error) => {
                this.store.app.setModalData({
                    title: 'Ошибка',
                    body: error.data.message,
                });
            }).finally(() => {
                this.setState({
                    ...this.state,
                    loadingSendFiles: false,
                });
            });
        }
    }

    componentDidMount() {
        this.getFileTypes();
    }

    render() {
        return (
            <AppLayout
                hasBackground={false}
            >
                {this.state.isShowSuccessModal
                    && <Modal
                        title={'Файлы успешно добавлены'}
                        footer={
                            <Button
                                onClick={() => window.open(`/claims/${this.state.claimId}`, '_self')}
                                style={{ width: '100%' }}
                                size="lg"
                                variant="primary">{ 'Продолжить' }</Button>
                        }
                        handleClose={() => window.open(`/claims/${this.state.claimId}`, '_self')} />
                }
                <div className={styles.AddDocumentsPageWrapper}>
                    <Wrapper>
                        <TopWrapper>
                            <Container fluid>
                                <div
                                    className={styles.AddDocumentsPageBackButton}
                                    onClick={() => window.open(`/claims/${this.state.claimId}`, '_self')}>
                                    Назад к заявлению
                                </div>
                                <Heading>
                                    {this.state.title}
                                </Heading>
                                <div className={styles.AddDocumentsPageContent}>
                                    <form className={styles.AddDocumentsPageForm}>
                                        <Heading level={4}>{ 'Прикрепите указанные документы' }</Heading>
                                        <div className={styles.AddDocumentsPageFilesContent}>
                                            {this.state.fileTypes.map((fileType, fileTypeIndex) => (
                                                <div
                                                    key={fileTypeIndex}
                                                    style={{ width: '100%', marginTop: fileTypeIndex !== 0 ? 30 : 0 }}
                                                >
                                                    <FileLoader
                                                        title={'Добавить'}
                                                        label={fileType.name}
                                                        description={fileType.prompt}
                                                        accept={".jpg,.jpeg,.png,.pdf"}
                                                        onChange={(e) => this.onChangeFiles(e, fileType.code)}
                                                        files={(
                                                            this.state.filesToSend.filter((file) => file.fileType === fileType.code).map((file, fileIndex) => (
                                                                <File
                                                                    key={fileIndex}
                                                                    name={file.name}
                                                                    onRemove={() => this.removeFile(file.id)}
                                                                />
                                                            )).reverse()
                                                        )}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                        <div className={styles.AddDocumentsPageAddButton}>
                                            <Button
                                                type="submit"
                                                variant="primary"
                                                size="lg"
                                                style={{ width: '40%' }}
                                                loading={this.state.loadingSendFiles}
                                                onClick={this.onSubmitSendFiles}
                                            >
                                                Отправить
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            </Container>
                        </TopWrapper>
                    </Wrapper>
                </div>
            </AppLayout>
        );
    }
}

export default inject('store')(observer(AddDocumentsPage));
