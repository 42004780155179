import React from 'react';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import cookies from 'js-cookie';
import AppLayout from 'layouts/AppLayout';
import TopWrapper from 'components/TopWrapper';
import Wrapper from 'components/Wrapper';
import Container from 'components/Container';
import Heading from 'components/Heading';
import InputField from 'components/InputField';
import DateInputField from 'components/DateInputField';
import MaskedInputField from 'components/MaskedInputField';
import Button from 'components/Button';
import File from 'components/File';
import FileLoader from 'components/FileLoader';
import Modal from 'components/Modal';
import ModalOverlay from 'components/ModalOverlay';
import BaseTable from 'components/BaseTable';
import CheckSmsCodeCard from 'components/CheckSmsCodeCard';
import AutocompleteInputField from 'components/AutocompleteInputField';
import {
    STATEMENT_SEND_SMS,
    DATA_CHANGE_GET_ITEM,
    DATA_CHANGE_CREATE_INSUMER,
    DATA_CHANGE_CHANGE_REQUEST,
    DATA_CHANGE_SEND_CHANGE_REQUEST,
    DATA_CHANGE_ADD_REQUEST_FILE,
    DATA_CHANGE_DELETE_REQUEST_FILE,
    DATA_CHANGE_GET_LIST,
    SUGGESTIONS_ADDRESS,
} from 'api';

import styles from './styles.module.scss';
import {sanitizeText} from "../../helpers/helpers";

class SettingsPage extends React.Component {
    constructor(props) {
        super(props);

        this.popup = {
            description1: 'Для изменения требуется заявление. Заполните необходимые поля и нажмите "Изменить мои персональные данные". Далее заявление будет сформировано и направлено на рассмотрение.',
            description2: 'Для изменения требуется заявление. Заполните необходимые поля, приложите скан копию документа, удостоверяющего личность Заявителя (Паспорт) и нажмите "Изменить мои персональные данные". Далее заявление будет сформировано и направлено на рассмотрение.',
        };
        this.store = props.store;

        this.state = {
            title: 'Профиль',

            requestId: null,

            claimList: [],

            files: [],

            fileTypes: [],

            form: {
                applicantBirthday: '',
                applicantBirthplace: '',
                applicantEmail: '',
                applicantInn: '',
                applicantName: '',
                applicantPassportSeries: '',
                applicantPassportNumber: '',
                applicantPassportIssueDate: '',
                applicantPassportIssuer: '',
                applicantPassportIssuerCode: '',
                applicantPhone: '',
                applicantRegistrationAddress: '',
                applicantActualAddress: '',
            },

            checkout: {
                isShowForm: false,
                isShowSuccessModal: false,
                code: '',
                codeError: null,
                resendLoading: false,
                submitLoading: false,
                submitDisable: false,
            },

            applicantError: {},

            suggestionsAddressResult: {},

            fetchPoliciesLikeCompany: !JSON.parse(cookies.get('fetchPoliciesLikeCompany') || true),
            required: false,
        };

        this.toggleFetchPoliciesLikeCompany = this.toggleFetchPoliciesLikeCompany.bind(this);

        this.openConfirmationForm = this.openConfirmationForm.bind(this);
        this.closeConfirmationForm = this.closeConfirmationForm.bind(this);

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.debounceEditRequest = _.debounce(this.editRequest, 1500);
        this.debounceFindSuggestionsAddress = _.debounce(this.findSuggestionsAddress, 50);
    }

    toggleFetchPoliciesLikeCompany(e) {
        const { checked } = e.target;

        this.setState({
            ...this.state,
            fetchPoliciesLikeCompany: checked,
        });

        cookies.set('fetchPoliciesLikeCompany', !checked);
    }

    async componentDidMount() {
        document.title = this.state.title;

        DATA_CHANGE_GET_LIST(false, true)
            .then((data) => {
                let { result: { dataChangeRequests = [] } } = data;

                dataChangeRequests = dataChangeRequests.filter((item) => !item.policy);

                this.setState({
                    ...this.state,
                    claimList: dataChangeRequests,
                });
            });
    }

    async createRequest() {
        this.setState({
            ...this.state,
            checkout: {
                ...this.state.checkout,
                submitLoading: true,
            },
        });

        await DATA_CHANGE_CREATE_INSUMER()
            .then(async (data) => {
                const { result: { id } } = data;

                await DATA_CHANGE_GET_ITEM(id)
                    .then((itemData) => {
                        const { result: { form = {}, files = [], fileTypes = [] } } = itemData;

                        Object.keys(form).forEach((key) => {
                            if (!form[key]) form[key] = '';
                        });

                        this.setState({
                            ...this.state,
                            requestId: id,
                            form,
                            files,
                            fileTypes,
                            checkout: {
                                ...this.state.checkout,
                                submitLoading: false,
                            },
                        });
                    });
            });
    }

    editRequest() {
        return DATA_CHANGE_CHANGE_REQUEST(this.state.requestId, this.state.form);
    }

    sendRequest() {
        DATA_CHANGE_SEND_CHANGE_REQUEST(this.state.requestId, this.state.checkout.code)
            .then(() => {
                this.setState({
                    ...this.state,
                    requestId: null,
                    checkout: {
                        ...this.state.checkout,
                        isShowForm: false,
                        isShowSuccessModal: true,
                        codeError: null,
                        submitLoading: false,
                    },
                });
            }).catch((error) => {
                const { message } = error.data;

                this.setState({
                    ...this.state,
                    checkout: {
                        ...this.state.checkout,
                        codeError: message,
                        submitLoading: false,
                    },
                });
            });
    }

    openConfirmationForm(e) {
        e.preventDefault();

        this.setState({
            ...this.state,
            checkout: {
                ...this.state.checkout,
                submitLoading: true,
            },
        });

        STATEMENT_SEND_SMS(this.store.profile.user.phone)
            .then(() => {
                this.setState({
                    ...this.state,
                    checkout: {
                        ...this.state.checkout,
                        isShowForm: true,
                        codeError: null,
                        submitLoading: false,
                    },
                });
            }).catch((error) => {
                const { message } = error.data;

                this.setState({
                    ...this.state,
                    checkout: {
                        ...this.state.checkout,
                        codeError: message,
                        submitLoading: false,
                    },
                });
            });
    }

    closeConfirmationForm(e) {
        e.preventDefault();

        this.setState({
            ...this.state,
            checkout: {
                isShowForm: false,
                isShowSuccessModal: false,
                code: '',
                codeError: null,
                resendLoading: false,
                submitLoading: false,
            },
        });
    }

    deleteFile(id) {
        const files = [...this.state.files];
        const index = files.findIndex((item) => item.fileId === id);

        files.splice(index, 1);

        this.setState({
            ...this.state,
            files,
        }, () => {
            DATA_CHANGE_DELETE_REQUEST_FILE(this.state.requestId, id);
        });
    }

    async onChangeFiles(e, type) {
        const uploadedFiles = [...e.target.files];
        const files = [...this.state.files];
        const filesError = [];

        e.target.value = null;

        for (let i = 0; i < uploadedFiles.length; i += 1) {
            const maxFileLength = 9;
            const maxFileSize = 10;
            const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;
            const file = uploadedFiles[i];
            const { size: fileSize, name: fileName } = file;
            const fileSizeMB = (fileSize / (1024 * 1024)).toFixed(2);

            const checkFileTypeLength = Boolean(files.filter((item) => item.fileType === type).length >= maxFileLength);
            const checkAllowedExtensions = allowedExtensions.exec(fileName);

            if (checkFileTypeLength) {
                filesError.push(`К загрузке допускается всего ${maxFileLength} файлов.`);

                break;
            } else if (!checkAllowedExtensions) {
                filesError.push(`Недопустимое расширение у файла ${fileName}`);
            } else if (fileSizeMB > maxFileSize) {
                filesError.push(`Размер файла ${fileName} превышает допустимые ${maxFileSize}мб.`);
            } else {
                files.push({
                    name: fileName,
                    fileType: type,
                    tempIndex: i,
                });

                this.setState({
                    ...this.state,
                    files,
                });

                await DATA_CHANGE_ADD_REQUEST_FILE(type, this.state.requestId, file)
                    .then((data) => {
                        const { result } = data;

                        const index = files.findIndex((item) => item.tempIndex === i);

                        files[index].fileId = result;
                        delete files[index].tempIndex;

                        this.setState({
                            ...this.state,
                            files,
                        });
                    }).catch((e) => {
                    filesError.push(e.data.message + " " + fileName);
                    files.pop();

                    this.setState({
                        ...this.state,
                        files,
                    })
                })
            }
        }

        if (filesError.length !== 0) {
            this.store.app.setModalData({
                title: 'Ошибка',
                body: filesError.join('\n'),
            });
        }
    }

    findSuggestionsAddress(name, value) {
        SUGGESTIONS_ADDRESS(value).then((data) => {
            let { suggestions = [] } = data;

            suggestions = suggestions.map((item) => item.unrestricted_value);

            this.setState({
                ...this.state,
                suggestionsAddressResult: {
                    [name]: suggestions,
                },
            });
        });
    }

    onChange(e) {
        const { value, name } = e.target;
        let requiredDoc = this.state.required;
        switch (name) {
        case 'applicantName':
        case 'applicantPassportSeries':
        case 'applicantPassportNumber':
        case 'applicantPassportIssuer':
        case 'applicantPassportIssueDate':
        case 'applicantPassportIssuerCode':
            if (value !== '') {
                requiredDoc = true;
            }
            break;
        case 'applicantActualAddress':
        case 'applicantRegistrationAddress':
            if (value !== '') {
                requiredDoc = true;
            }
            this.debounceFindSuggestionsAddress(name, value);
            break;
        default:
            break;
        }

        this.setState({
            ...this.state,
            form: {
                ...this.state.form,
                [name]: sanitizeText(value),
            },
            required: requiredDoc,
        }, () => {
            this.debounceEditRequest();
        });
    }

    async onSubmit(e) {
        e.preventDefault();

        this.setState({
            ...this.state,
            checkout: {
                ...this.state.checkout,
                submitLoading: true,
            },
        });

        await this.editRequest();

        this.sendRequest();
    }

    submitDisabled() {
        const applicantError = {};

        let isDisabled = this.state.checkout.submitLoading || this.state.checkout.submitDisable;

        if (this.state.required && this.state.files.length < 1) isDisabled = true;

        if (this.state.form.applicantInn && this.state.form.applicantInn.length < 12) {
            isDisabled = true;
            applicantError.applicantInn = true;
        }

        if (!this.state.form.applicantEmail) {
            isDisabled = true;
            applicantError.applicantEmail = true;
        } else if (this.state.form.applicantEmail && !new RegExp('^([a-zA-Z0-9_-]+\\.)*[a-zA-Z0-9_-]+@[a-z0-9_-]+(\\.[a-z0-9_-]+)*\\.[a-z]{2,6}$').test(this.state.form.applicantEmail)) {
            isDisabled = true;

            applicantError.applicantEmail = true;
        }

        if (JSON.stringify(this.state.applicantError) !== JSON.stringify(applicantError)) {
            this.setState({
                ...this.state,
                applicantError,
            });
        }

        return isDisabled;
    }

    render() {
        const { user } = this.store.profile;
        let passportData = {};

        if (user.documents && user.documents.length) {
            passportData = user.documents.find((document) => document.type.code === 'IdentityDocument_RussianFederationPassport');
        }

        return (
            <AppLayout
                hasBackground={false}
            >
                {this.state.checkout.isShowForm && (
                    <ModalOverlay handleClose={(e) => this.closeConfirmationForm(e)}>
                        <CheckSmsCodeCard
                            code={this.state.checkout.code}
                            codeError={this.state.checkout.codeError}
                            resendLoading={this.state.checkout.resendLoading}
                            submitLoading={this.state.checkout.submitLoading}
                            resendTime={60}
                            onCodeChange={(e) => this.setState({
                                ...this.state,
                                checkout: {
                                    ...this.state.checkout,
                                    code: e.target.value,
                                },
                            })}
                            onClose={(e) => this.closeConfirmationForm(e)}
                            onResend={() => this.sendConfirmationCode()}
                            onSubmit={(e) => this.onSubmit(e)}
                        />
                    </ModalOverlay>
                )}

                {this.state.checkout.isShowSuccessModal && (
                    <Modal
                        title={ 'Спасибо!' }
                        body={
                            <p>Заявление успешно отправлено</p>
                        }
                        footer={
                            <Button
                                style={{ width: '100%' }}
                                size="lg"
                                variant="primary"
                                onClick={this.closeConfirmationForm}>{ 'Продолжить' }</Button>
                        }
                        handleClose={this.closeConfirmationForm} />
                )}

                <div className={styles.SettingsPageWrapper}>
                    <Wrapper>
                        <TopWrapper>
                            <Container fluid>
                                <Heading>{ this.state.title }</Heading>

                                <div className={styles.SettingsPageSide}>
                                    {!this.state.requestId && (
                                        <React.Fragment>
                                            <div className={styles.Card}>
                                                <div className={styles.CardWrapper}>
                                                    <div className={styles.CardRow}>
                                                        <div data-name="fullname">
                                                            <InputField
                                                                label="ФИО"
                                                                size="lg"
                                                                type="text"
                                                                placeholder="ФИО"
                                                                name="fullName"
                                                                value={user.fullName || ''}
                                                                onChange={() => {}}
                                                                disabled
                                                            />
                                                        </div>

                                                        <div data-name="birthDate">
                                                            <DateInputField
                                                                mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                                                                label="Дата рождения"
                                                                size="lg"
                                                                icon="calendar"
                                                                format="dd.MM.yyyy"
                                                                placeholder="дд.мм.гггг"
                                                                value={(user.birthDate && moment(user.birthDate).toDate()) || ''}
                                                                onChange={() => {}}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className={styles.CardRow}>
                                                        <div data-name="passportSeries">
                                                            <MaskedInputField
                                                                mask={[/[0-9]/, /\d/, /\d/, /\d/]}
                                                                label={'Паспорт'}
                                                                size="lg"
                                                                type="text"
                                                                placeholder="Серия"
                                                                name="documentSeries"
                                                                value={passportData.series || ''}
                                                                onChange={() => {}}
                                                                disabled
                                                            />
                                                        </div>

                                                        <div data-name="passportNumber">
                                                            <MaskedInputField
                                                                mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                                                size="lg"
                                                                type="text"
                                                                placeholder="Номер"
                                                                name="documentNumber"
                                                                value={passportData.number || ''}
                                                                onChange={() => {}}
                                                                disabled
                                                            />
                                                        </div>

                                                        <div data-name="passportIssueDate">
                                                            <DateInputField
                                                                mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                                                                label="Дата выдачи"
                                                                size="lg"
                                                                icon="calendar"
                                                                format="dd.MM.yyyy"
                                                                placeholder="дд.мм.гггг"
                                                                value={(passportData.issueDate && moment(passportData.issueDate).toDate()) || ''}
                                                                onChange={() => {}}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className={styles.CardRow}>
                                                        <div data-name="passportIssuer">
                                                            <InputField
                                                                label="Кем выдан"
                                                                size="lg"
                                                                type="text"
                                                                placeholder="Кем выдан"
                                                                name="issuedBy"
                                                                value={passportData.issuer || ''}
                                                                onChange={() => {}}
                                                                disabled
                                                            />
                                                        </div>

                                                        <div data-name="passportIssuerCode">
                                                            <InputField
                                                                label="Код подразделения"
                                                                size="lg"
                                                                type="text"
                                                                placeholder="Код подразделения"
                                                                name="applicantPassportIssuerCode"
                                                                value={passportData.issuerCode || ''}
                                                                onChange={() => {}}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className={styles.CardRow}>
                                                        <InputField
                                                            label="ИНН"
                                                            size="lg"
                                                            type="text"
                                                            placeholder="ИНН"
                                                            name="inn"
                                                            value={user.inn || ''}
                                                            onChange={() => {}}
                                                            disabled
                                                        />
                                                    </div>

                                                    {user.addresses && user.addresses.map((address, index) => (
                                                        <div
                                                            key={index}
                                                            className={styles.CardRow}
                                                        >
                                                            <InputField
                                                                label={address.type.name}
                                                                size="lg"
                                                                type="text"
                                                                placeholder={address.type.name}
                                                                name={address.type.code}
                                                                value={address.fullAddress || ''}
                                                                onChange={() => {}}
                                                                disabled
                                                            />
                                                        </div>
                                                    ))}

                                                    <div className={styles.CardRow}>
                                                        <div data-name="phone">
                                                            <MaskedInputField
                                                                mask={['+', /[7-7]/, ' ', /[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
                                                                label={'Телефон'}
                                                                size="lg"
                                                                type="tel"
                                                                placeholder="+7 _ _ _ - _ _ _ - _ _ - _ _"
                                                                name="phone"
                                                                value={user.phone || ''}
                                                                onChange={() => {}}
                                                                disabled
                                                            />
                                                        </div>

                                                        <div data-name="email">
                                                            <InputField
                                                                label="E-mail"
                                                                size="lg"
                                                                type="text"
                                                                placeholder="E-mail"
                                                                name="email"
                                                                value={user.email || ''}
                                                                onChange={() => {}}
                                                                onEdit={() => this.store.app.setIsShowVerifyEmailModal(true)}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={styles.CardButtons}>
                                                    <Button
                                                        variant="primaryOutline"
                                                        size="lg"
                                                        type="button"
                                                        loading={this.state.checkout.submitLoading}
                                                        disabled={this.state.checkout.submitLoading}
                                                        onClick={() => this.createRequest()}
                                                    >{ 'Изменить мои персональные данные' }</Button>
                                                </div>
                                            </div>

                                            {this.state.claimList.length !== 0 && (
                                                <div style={{ marginTop: 85 }}>
                                                    <Heading level="3">{ 'История изменений' }</Heading>

                                                    <BaseTable className={styles.ClaimTable}>
                                                        <tbody>
                                                            {this.state.claimList.map((item, itemIndex) => (
                                                                <tr
                                                                    key={itemIndex}
                                                                    data-status={item.status.code}
                                                                >
                                                                    <td data-col="date">{ moment(item.dateAndTime).format('DD.MM.YYYY') }</td>

                                                                    <td data-col="name">
                                                                        <span>{ item.name }</span>
                                                                        <span data-rel="subTitle"></span>
                                                                    </td>

                                                                    <td data-col="status">{ item.status.name }</td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </BaseTable>
                                                </div>
                                            )}
                                        </React.Fragment>
                                    )}

                                    {this.state.requestId && (
                                        <div className={styles.Card}>
                                            <div className={styles.CardWrapper}>
                                                <div className={styles.CardRow}>
                                                    <div data-name="fullname">
                                                        <InputField
                                                            label="ФИО"
                                                            questionLabel ={this.popup.description2}
                                                            size="lg"
                                                            type="text"
                                                            placeholder="ФИО"
                                                            name="applicantName"
                                                            value={this.state.form.applicantName}
                                                            onChange={this.onChange}
                                                        />
                                                    </div>

                                                    <div data-name="birthDate">
                                                        <DateInputField
                                                            mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                                                            label="Дата рождения"
                                                            size="lg"
                                                            icon="calendar"
                                                            format="dd.MM.yyyy"
                                                            placeholder="дд.мм.гггг"
                                                            name="applicantBirthday"
                                                            value={this.state.form.applicantBirthday && moment(this.state.form.applicantBirthday).toDate()}
                                                            onChange={(value) => this.onChange({ target: { value: moment(value).format('YYYY-MM-DDTHH:mm:ss'), name: 'applicantBirthday' } })}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>

                                                <div className={styles.CardRow}>
                                                    <div data-name="passportSeries">
                                                        <MaskedInputField
                                                            mask={[/[0-9]/, /\d/, /\d/, /\d/]}
                                                            label={'Паспорт'}
                                                            questionLabel ={this.popup.description2}
                                                            size="lg"
                                                            type="text"
                                                            placeholder="Серия"
                                                            name="applicantPassportSeries"
                                                            value={this.state.form.applicantPassportSeries}
                                                            onChange={this.onChange}
                                                        />
                                                    </div>

                                                    <div data-name="passportNumber">
                                                        <MaskedInputField
                                                            mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                                            size="lg"
                                                            type="text"
                                                            placeholder="Номер"
                                                            name="applicantPassportNumber"
                                                            value={this.state.form.applicantPassportNumber}
                                                            onChange={this.onChange}
                                                        />
                                                    </div>
                                                    <div data-name="passportIssueDate">
                                                        <DateInputField
                                                            mask={[/[0-9]/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
                                                            maxDate={moment().toDate()}
                                                            questionLabel ={this.popup.description2}
                                                            label="Дата выдачи"
                                                            size="lg"
                                                            icon="calendar"
                                                            format="dd.MM.yyyy"
                                                            placeholder="дд.мм.гггг"
                                                            name="applicantPassportIssueDate"
                                                            value={this.state.form.applicantPassportIssueDate && moment(this.state.form.applicantPassportIssueDate).toDate()}
                                                            onChange={(value) => this.onChange({ target: { value: moment(value).format('YYYY-MM-DDTHH:mm:ss'), name: 'applicantPassportIssueDate' } })}
                                                        />
                                                    </div>
                                                </div>

                                                <div className={styles.CardRow}>
                                                    <div data-name="fullname">
                                                        <InputField
                                                            label="Кем выдан"
                                                            questionLabel ={this.popup.description2}
                                                            size="lg"
                                                            type="text"
                                                            placeholder="Кем выдан"
                                                            name="applicantPassportIssuer"
                                                            value={this.state.form.applicantPassportIssuer}
                                                            onChange={this.onChange}
                                                        />
                                                    </div>
                                                    <div data-name="passportIssuerCode">
                                                        <MaskedInputField
                                                            mask={[/[0-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                                                            label="Код подразделения"
                                                            questionLabel ={this.popup.description2}
                                                            size="lg"
                                                            type="text"
                                                            placeholder="Код подразделения"
                                                            name="applicantPassportIssuerCode"
                                                            value={this.state.form.applicantPassportIssuerCode}
                                                            onChange={this.onChange}
                                                        />
                                                    </div>
                                                </div>

                                                <div className={styles.CardRow}>
                                                    <MaskedInputField
                                                        mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
                                                        label="ИНН"
                                                        questionLabel ={this.popup.description2}
                                                        size="lg"
                                                        type="text"
                                                        placeholder="ИНН"
                                                        name="applicantInn"
                                                        value={this.state.form.applicantInn}
                                                        error={this.state.applicantError.applicantInn}
                                                        onChange={this.onChange}
                                                    />
                                                </div>

                                                <div className={styles.CardRow}>
                                                    <AutocompleteInputField
                                                        label="Адрес регистрации"
                                                        questionLabel ={this.popup.description2}
                                                        size="lg"
                                                        type="text"
                                                        placeholder="Адрес регистрации"
                                                        name="applicantRegistrationAddress"
                                                        value={this.state.form.applicantRegistrationAddress}
                                                        items={this.state.suggestionsAddressResult.applicantRegistrationAddress || []}
                                                        includeSearch={false}
                                                        setValue={(value) => this.onChange({ target: { value, name: 'applicantRegistrationAddress' } }) }
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className={styles.CardRow}>
                                                    <AutocompleteInputField
                                                        label="Адрес фактический"
                                                        questionLabel ={this.popup.description1}
                                                        size="lg"
                                                        type="text"
                                                        placeholder="Адрес фактический"
                                                        name="applicantActualAddress"
                                                        value={this.state.form.applicantActualAddress}
                                                        items={this.state.suggestionsAddressResult.applicantActualAddress || []}
                                                        includeSearch={false}
                                                        setValue={(value) => this.onChange({ target: { value, name: 'applicantActualAddress' } }) }
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                                <div className={styles.CardRow}>
                                                    {this.state.fileTypes.map((fileType, fileTypeIndex) => (
                                                        <div
                                                            key={fileTypeIndex}
                                                            style={{ width: '100%', marginTop: fileTypeIndex !== 0 ? 30 : 0 }}
                                                        >
                                                            <FileLoader
                                                                title={'Добавить'}
                                                                label={fileType.name}
                                                                required={this.state.required}
                                                                accept={".jpg,.jpeg,.png,.pdf"}
                                                                onChange={(e) => this.onChangeFiles(e, fileType.code)}
                                                                files={(
                                                                    this.state.files.filter((file) => file.fileType === fileType.code).map((file, fileIndex) => (
                                                                        <File
                                                                            key={fileIndex}
                                                                            name={file.name}
                                                                            href={`/api?method=Insurance&action=getFile&FileId=${file.fileId}&FileName=${file.name}`}
                                                                            loading={file.tempIndex !== undefined}
                                                                            onRemove={file.fileId ? () => this.deleteFile(file.fileId) : undefined }
                                                                        />
                                                                    )).reverse()
                                                                )}
                                                            />
                                                        </div>
                                                    ))}
                                                </div>

                                                <div className={styles.CardRow}>
                                                    <div data-name="phone">
                                                        <MaskedInputField
                                                            mask={['+', /[7-7]/, ' ', /[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]}
                                                            label={'Телефон'}
                                                            questionLabel ={this.popup.description1}
                                                            size="lg"
                                                            type="tel"
                                                            placeholder="+7 _ _ _ - _ _ _ - _ _ - _ _"
                                                            name="applicantPhone"
                                                            value={this.state.form.applicantPhone}
                                                            error={this.state.applicantError.applicantPhone}
                                                            onChange={this.onChange}
                                                        />
                                                    </div>
                                                    <InputField
                                                        text="Укажите ваш email для получения уведомлений о смене статусов обработки заявления"
                                                        questionLabel ={this.popup.description1}
                                                        size="lg"
                                                        type="text"
                                                        placeholder="E-mail"
                                                        name="applicantEmail"
                                                        required={true}
                                                        value={this.state.form.applicantEmail}
                                                        error={this.state.applicantError.applicantEmail}
                                                        onChange={this.onChange}
                                                    />
                                                </div>
                                            </div>

                                            <div className={styles.CardButtons}>
                                                <Button
                                                    href={`/api?method=datachange&action=previewfile&dataChangeRequestId=${this.state.requestId}&fileTypeCode=FT-1002`}
                                                    variant="primaryOutline"
                                                    size="lg"
                                                    type="button"
                                                >
                                                    { 'Предпросмотр в PDF' }
                                                </Button>

                                                <Button
                                                    variant="primary"
                                                    size="lg"
                                                    type="submit"
                                                    loading={this.state.checkout.submitLoading}
                                                    disabled={this.submitDisabled()}
                                                    onClick={this.openConfirmationForm}
                                                >{ 'Отправить' }</Button>
                                            </div>

                                        </div>
                                    )}
                                </div>
                            </Container>
                        </TopWrapper>
                    </Wrapper>
                </div>
            </AppLayout>
        );
    }
}


export default inject('store')(observer(SettingsPage));
