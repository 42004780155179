import React from 'react';
import Container from 'components/Container';
import styles from './styles.module.scss';

const Footer = () => (
    <footer className={styles.Footer}>
        <Container fluid>
            <address
                itemScope
                itemType="http://schema.org/Organization"
                className={styles.FooterAddress}
            >
            </address>
            <div className={styles.FooterCopyright}>
                <div className={styles.FooterCopyrightText}>
                    {'© ООО «Совкомбанк страхование жизни». Лицензии Банка России: СЖ № 4105, СЛ № 4105.'}
                </div>
            </div>
        </Container>
    </footer>
);

export default Footer;
