import React from 'react';
import Container from 'components/Container';
import cn from 'classnames';
import styles from './styles.module.scss';

const HeaderFirstNavBar = () => (
    <Container fluid>
        <div className={styles.HeaderFirstNavBar}>
            <a
                href="https://sovcomlife.ru/"
                target='_blank'
                rel="noopener noreferrer"
                className={cn(styles.HeaderFirstNavBarItem, styles.HeaderFirstNavBarReturnLink)}>
                <span>{'Вернуться на сайт'}</span>
            </a>

            <div className={styles.HeaderFirstNavBarItems}>
                <a
                    href='https://pyrus.sovcombank.ru/form/371527'
                    target='_blank'
                    rel="noopener noreferrer"
                    className={styles.HeaderFirstNavBarItem}>
                        Связаться с нами
                </a>
            </div>
            <a
                href={'tel:88005103510'}
                className={styles.HeaderFirstNavBarPhone}>
                {'8 800 510-3-510'}
            </a>
            <span className={styles.HeaderNavPhoneText}>
                    (будние дни с 8:00 до 19:00, Мск)
            </span>
            <a
                href="https://sovcomlife.ru/support-centre/make-a-claim"
                target="_blank"
                rel="noopener noreferrer"
            >
                <div className={styles.HeaderFirstNavBarSos}>{'SOS'}</div>
            </a>
        </div>
    </Container>
);

export default HeaderFirstNavBar;
